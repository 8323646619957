import {Injectable} from '@angular/core';
import {BaseService} from '../../common/base.service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ProductionJob} from './production-job';
import {CreateStockWithProductionJob} from './pot-jobs/create-stock-dialog/create-stock-with-production-job';
import {UpdateStockAndProductionJob} from './pot-stock-count-and-place/assign-location-dialog/UpdateStockAndProductionJob';

@Injectable()
export class PotProductionJobService extends BaseService {

  private url = this.baseUrl + '/potting-production-job';

  getAllProductionJobsForWeek(fromDate: string, untilDate: string): Observable<ProductionJob[]> {
    const dateObject = {fromDate: fromDate, untilDate: untilDate};
    return this.http.post<ProductionJob[]>(this.url + '/week', dateObject)
      .pipe(catchError(this.handleError('ophalen van alle oppot opdrachten', [])));
  }

  getAllProductionJobs(): Observable<ProductionJob[]> {
    return this.http.post<ProductionJob[]>(this.url + '/week', null)
      .pipe(catchError(this.handleError('ophalen van alle oppot opdrachten', [])));
  }

  createNewStockEntity(newStockEntity: CreateStockWithProductionJob) {
    return this.http.post<ProductionJob[]>(this.url + '/create-stock', newStockEntity)
      .pipe(catchError(this.handleError('Aanmaken van stock', [])));
  }

  updateStockEntityWithProductionJob(updateStockAndProductionJob: UpdateStockAndProductionJob) {
    return this.http.put<ProductionJob[]>(this.url + `/update-production-job`, updateStockAndProductionJob)
      .pipe(catchError(this.handleError('updaten van stock', [])));
  }

  markProductionJobAsProduced(productionJobId: number) {
    return this.http.put<ProductionJob[]>(this.url + '/mark-as-produced/' + productionJobId, {})
      .pipe(catchError(this.handleError('Aanmaken van stock', [])));
  }

  fetchProductionJobsMarkedProduced(): Observable<ProductionJob[]> {
    return this.http.get<ProductionJob[]>(this.url + '/production-state?status=PRODUCED')
      .pipe(catchError(this.handleError('Ophalen van oppot opdrachten met status geproduceerd', [])));
  }
}
