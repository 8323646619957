    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <mat-form-field appearance="outline">
                <mat-label>Gebruiker</mat-label>
                <input matInput type="text" formControlName="username" #username />
                <mat-error *ngIf="f.username.invalid">Moet ingevuld zijn</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Wachtwoord</mat-label>
                <input matInput type="password" formControlName="password" />
                <mat-error *ngIf="f.password.invalid">Moet ingevuld zijn</mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" [disabled]="loading">Login</button>
            <div *ngIf="error" class="error">{{error}}</div>
    </form>
