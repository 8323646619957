import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PotStock} from '../../pot';

@Component({
  selector: 'app-change-vrij-dialog',
  templateUrl: './change-vrij-dialog.component.html',
  styleUrls: ['./change-vrij-dialog.component.scss']
})
export class ChangeVrijDialogComponent implements OnInit {

  customVrij: number;
  vrijValidationError: string;

  constructor(
    public dialogRef: MatDialogRef<ChangeVrijDialogComponent, PotStock>,
    @Inject(MAT_DIALOG_DATA) public voorraad: PotStock
  ) { }

  ngOnInit() {
    this.customVrij = this.voorraad.total;
  }

  submit() {
    if (this.customVrij == null) {
      this.vrijValidationError = 'Geen waarde ingegeven';
    } else if (this.customVrij > this.voorraad.total) {
      this.vrijValidationError = 'Vrij mag niet meer zijn dan totaal: ' + this.voorraad.total;
    } else {
      this.voorraad.amountFitForSale = this.customVrij;
      this.dialogRef.close(this.voorraad);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
