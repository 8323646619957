<h2 mat-dialog-title>Hoe moet de partij worden afgeboekt?</h2>
<mat-button-toggle-group [(ngModel)]="bookingMethod" name="bookingMethod" aria-label="Boek Methode" #group="matButtonToggleGroup">
    <mat-button-toggle value="unsellable">
        <mat-icon>money_off</mat-icon>Onverkoopbaar
    </mat-button-toggle>
    <mat-button-toggle value="dead">
        <mat-icon>delete_forever</mat-icon>
        Dood
    </mat-button-toggle>
</mat-button-toggle-group>
<p class="warning-text mat-body-1" *ngIf="bookingMethod">Dit zal de voorraadpartij verwijderen, weet je het zeker?</p>
<mat-dialog-actions>
    <button mat-raised-button (click)="cancel()">
        <i class="material-icons" aria-label="Annuleren">backspace</i>
        Annuleren
    </button>
    <button [disabled]="!bookingMethod" mat-raised-button color="warn" (click)="onSubmitClick()">
        Bevestigen
    </button>
</mat-dialog-actions>
