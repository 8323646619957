<div class="loading-shade" *ngIf="loading$ | async">
  <mat-spinner></mat-spinner>
</div>

<div id="buttonBar">
  <mat-button-toggle-group id="dayToggle" #dayToggle="matButtonToggleGroup" [(ngModel)]="selectedDay"
                           (change)="applyFilter()">
    <mat-button-toggle value="ALL" aria-label="Alles">Alles</mat-button-toggle>
    <mat-button-toggle value="PAK" aria-label="Maandag of woensdag">Pakdag</mat-button-toggle>
  </mat-button-toggle-group>

  <div class="buttonBarRow">
    <button mat-raised-button color="primary" [disabled]="!selection.hasValue()"
            (click)="exportFustData()">
      <mat-icon>save</mat-icon>
      Fust artikelen toevoegen
    </button>
  </div>
</div>

<div id="tableContainer" #scrollContainer mat-elevation-z8>
  <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="hasFustArticles">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Heeft fust</th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox disabled color="primary" [(ngModel)]="row.hasFustArticles">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="deliverNr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>AfleverNr</th>
      <td mat-cell *matCellDef="let element"> {{element.deliverNr}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Naam</th>
      <td mat-cell *matCellDef="let element" [routerLink]="['/afchecklijst/', element.id]"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="allChecked">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Afgecheckt</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.allItemsChecked" color="primary">thumb_up_alt</mat-icon>
        <mat-icon *ngIf="!element.allItemsChecked" color="warn">warning</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="totalBlok">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Blokpallets <br> {{totalBlok}} </th>
      <td mat-cell *matCellDef="let element">
        <app-counter-text [(count)]="element.pallets" (countChange)="onChange(element)" [minimum]="0"
                          [vertical]="false">
        </app-counter-text>
      </td>
    </ng-container>

    <ng-container matColumnDef="totalEuro">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Europallets <br> {{totalEuro}} </th>
      <td mat-cell *matCellDef="let element">
        <app-counter-text [(count)]="element.europallets" (countChange)="onChange(element)" [minimum]="0"
                          [vertical]="false">
        </app-counter-text>
      </td>
    </ng-container>

    <ng-container matColumnDef="dropOffLocation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Waar</th>
      <td mat-cell *matCellDef="let element"> {{element.dropOffLocation}} </td>
    </ng-container>

    <ng-container matColumnDef="shipmentDay">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Dag</th>
      <td mat-cell *matCellDef="let element"> {{element.shipmentDay}} </td>
    </ng-container>

    <ng-container matColumnDef="shipmentWeek">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Week</th>
      <td mat-cell *matCellDef="let element"> {{element.shipmentWeek}} </td>
    </ng-container>

    <ng-container matColumnDef="totalVml">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> vml <br> {{totalVml}}  </th>
      <td mat-cell *matCellDef="let element"> {{element.nl_vml + element.d_vml + element.dcw_vml}} </td>
    </ng-container>

    <ng-container matColumnDef="deltaVml">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>vml +- <br> {{totalDeltaVml}} </th>
      <td mat-cell *matCellDef="let element">
        <app-counter-text [(count)]="element.deltaVml" (countChange)="onChange(element)"
                          [vertical]="false"></app-counter-text>
      </td>
    </ng-container>

    <ng-container matColumnDef="totalVmh">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> vmh <br> {{totalVmh}} </th>
      <td mat-cell *matCellDef="let element"> {{element.nl_vmh + element.d_vmh + element.dcw_vmh}}</td>
    </ng-container>

    <ng-container matColumnDef="deltaVmh">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>vmh +- <br> {{totalDeltaVmh}} </th>
      <td mat-cell *matCellDef="let element">
        <app-counter-text [(count)]="element.deltaVmh" (countChange)="onChange(element)"
                          [vertical]="false"></app-counter-text>
      </td>
    </ng-container>

    <ng-container matColumnDef="together">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Samen <br> {{totalSamen}}</th>
      <td mat-cell *matCellDef="let element"> {{element.together}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div id="no-orders" *ngIf="dataSource?.data.length == 0 && !(loading$ | async)">
    Er staan geen bonnen klaar
  </div>
</div>
