import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SalesOrder} from '../sales-order';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {BehaviorSubject, Subject} from 'rxjs';
import {SalesOrderService} from '../sales-order.service';
import {ToastrService} from 'ngx-toastr';
import {SummedSalesOrder} from './SummedSalesOrder';
import {WithScrollContainer} from '../../_helpers/with-scroll-container';
import {SelectionModel} from '@angular/cdk/collections';
import {tap} from 'rxjs/operators';
import {SalesOrderTotalDTO} from './SalesOrderTotalDTO';

@Component({
  selector: 'app-register-totals',
  templateUrl: './register-totals.component.html',
  styleUrls: ['./register-totals.component.scss']
})
export class RegisterTotalsComponent extends WithScrollContainer() implements OnInit, AfterViewInit, OnDestroy {
  private readonly onDestroy = new Subject<void>();

  selection = new SelectionModel<SalesOrderTotalDTO>(true, []);
  orders: SummedSalesOrder[] = [];
  selectedDay = 'ALL';
  dataSource = new MatTableDataSource<SummedSalesOrder>();
  displayedColumns: string[] = ['select', 'hasFustArticles', 'deliverNr', 'name', 'allChecked', 'totalBlok',
    'totalEuro', 'totalVml', 'deltaVml', 'totalVmh', 'deltaVmh', 'together', 'dropOffLocation', 'shipmentDay', 'shipmentWeek'];
  loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  totalEuro = 0;
  totalBlok = 0;
  totalVml = 0;
  totalVmh = 0;
  totalSamen = 0;
  totalDeltaVmh = 0;
  totalDeltaVml = 0;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private salesOrderService: SalesOrderService,
    private toastr: ToastrService,
  ) {
    super();
    this.dataSource.filterPredicate = order => this.isApplicableToSelectedDay(order);
  }

  ngOnInit() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.name ? item.name.toLocaleLowerCase() : '';
        case 'waar':
          return item.dropOffLocation ? item.dropOffLocation.toLocaleLowerCase() : '';
        default:
          return item[property];
      }
    };
    this.getCheckListOrders();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  public async onAttach() {
    await this.getCheckListOrders();
    super.onAttach();
  }

  private async getCheckListOrders() {
    this.loadingSubject.next(true);
    try {
      this.setOrders([]);
      this.setOrders(await this.salesOrderService.getSummedSalesOrders().toPromise());
      this.toastr.success('Ophalen van data gelukt!');
    } catch (error) {
      console.error('Error while getting checklist orders without items', error);
      this.toastr.error('Kon afchecklijst niet openen, probeer het later nog eens');
    } finally {
      this.loadingSubject.next(false);
    }
  }

  applyFilter() {
    this.loadingSubject.next(true);
    // This is purely to kickoff reevaluation of the filter. Filter string doenst matter, but cannot be empty
    this.dataSource.filter = ' ';
    this.calcTotalsForTableHeader();
    this.loadingSubject.next(false);
  }

  onChange(order: SalesOrder) {
    this.updateOrder(order);
  }

  private setOrders(orders: SummedSalesOrder[]) {
    this.orders = orders;
    this.dataSource.data = this.orders;
    this.calcTotalsForTableHeader();
  }

  private async updateOrder(order: SalesOrder) {
    this.loadingSubject.next(true);
    try {
      await this.salesOrderService.updateSalesOrder(order).toPromise();
    } catch (error) {
      console.error('Unable to save SalesOrder:\n' + error);
      this.toastr.error('Fout bij opslaan! Probeer het nog eens');
    } finally {
      this.calcTotalsForTableHeader();
      this.loadingSubject.next(false);
    }
  }

  private isApplicableToSelectedDay(order: SummedSalesOrder): boolean {
    switch (this.selectedDay) {
      case 'ALL':
        return true;
      case 'PAK':
        return order.pr === true;
    }
  }

  private calcTotalsForTableHeader() {
    this.totalEuro = 0;
    this.totalBlok = 0;
    this.totalVmh = 0;
    this.totalVml = 0;
    this.totalSamen = 0;
    this.totalDeltaVmh = 0;
    this.totalDeltaVml = 0;
    this.dataSource.filteredData.forEach(order => {
      this.totalEuro += order.europallets;
      this.totalBlok += order.pallets;
      this.totalVmh += order.nl_vmh + order.d_vmh + order.dcw_vmh;
      this.totalVml += order.nl_vml + order.d_vml + order.dcw_vml;
      this.totalSamen += order.together;
      this.totalDeltaVmh += order.deltaVmh;
      this.totalDeltaVml += order.deltaVml;
    });
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected >= numRows;
  }

  async exportFustData() {
    this.loadingSubject.next(true);
    this.salesOrderService.exportFustData(this.selection.selected).pipe(
      tap(_ => this.toastr.success('Fustgegevens opgeslagen'))
    ).subscribe({
      error: err => {
          this.toastr.error('Kon de totalen niet opslaan, herlaad aub de pagina');
      },
      complete: () => {
        this.selection.clear();
        this.getCheckListOrders();
      }
    });
  }
}
