import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retry(2),
            catchError((errorResponse: any) => {
                let errMsg: string;
                if (errorResponse instanceof ErrorEvent) {
                    // A client-side or network error occurred. Handle it accordingly.
                    errMsg = errorResponse.message ? errorResponse.message : errorResponse.toString();
                } else {
                    // The backend returned an unsuccessful response code.
                    const err = errorResponse.message || JSON.stringify(errorResponse.error);
                    errMsg = `${errorResponse.status} - ${errorResponse.statusText || ''} Details: ${err}`;
                }
                console.error(errMsg);
                return throwError(errorResponse);
            })
        );
    }
}
