import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-stale-update-dialog',
  templateUrl: './stale-update-dialog.component.html',
  styleUrls: ['./stale-update-dialog.component.scss']
})
export class StaleUpdateDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<StaleUpdateDialogComponent>) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }

  confirmReload() {
    window.location.reload();
  }
}
