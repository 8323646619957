<div class="loading-shade" *ngIf="loading$ | async">
  <mat-spinner></mat-spinner>
</div>
<div id="actionbar">
  <mat-form-field id="search-field" appearance="outline">
    <mat-icon matPrefix>qr_code_scanner</mat-icon>
    <mat-label>Scan barcode</mat-label>
    <input matInput #barcodeInput autofocus
           placeholder="Barcode" [(ngModel)]="barcode"
           (keyup)="barcodeSubject.next(barcode)"
           (keyup.enter)="barcodeSubject.next(barcode)"
           autocomplete="off">
    <button mat-button *ngIf="barcode" matSuffix mat-icon-button aria-label="Clear" (click)="barcode=undefined">
      <mat-icon>&#xE5CD;</mat-icon>
    </button>
  </mat-form-field>
  <button mat-button color="error" *ngIf="barcodes.length > 0" aria-label="Remove all" (click)="removeAll()">
    <mat-icon>delete</mat-icon> Verwijder alles
  </button>
  <button mat-button color="primary" *ngIf="barcodes.length > 0" aria-label="Save" (click)="save()">
    <mat-icon>print</mat-icon> Print {{barcodes.length}}
  </button>
</div>
<div #scrollContainer mat-elevation-z8 id="scroll-container" class="barcode-list">
  <div class="barcode-box" *ngFor="let barcode of (barcodes | reverse); index as i">
    <app-scan-print-item [barcode]="barcode" [index]="barcodes.length - i" (remove)="remove(barcode)"></app-scan-print-item>
  </div>
</div>
