import {Component, OnDestroy, OnInit} from '@angular/core';
import {LocationService} from '../../_services/location.service';
import {Subscription} from 'rxjs';
import {SelectedLocation} from './locations';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss']
})
export class LocationSelectorComponent implements OnInit, OnDestroy {

  selectedLocation: SelectedLocation;
  subscriptions = new Subscription();
  locations = Object.values(SelectedLocation);

  constructor(
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.selectedLocation = this.locationService.getSelectedLocation();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onLocationChange() {
    this.locationService.setSelectedLocation(this.selectedLocation);
  }

}
