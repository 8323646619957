import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-release-afroep-dialog',
  templateUrl: './release-afroep-dialog.component.html',
  styleUrls: ['./release-afroep-dialog.component.scss']
})
export class ReleaseAfroepDialogComponent {

  bookingMethod: string;
  displayedColumns: string[] = ['name', 'reservedAmount'];

  constructor(public dialogRef: MatDialogRef<ReleaseAfroepDialogComponent, Boolean>) { }

  confirmRelease() {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

}
