import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {filter, first, map} from 'rxjs/operators';
import {concat, interval, Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  updateAvailable$: Observable<boolean>;

  private connectionList = ['slow-2g', '2g', '3g', '4g'];
  private slowConnections = ['slow-2g', '2g'];

  constructor(
    private updates: SwUpdate,
    private appRef: ApplicationRef,
    private toastr: ToastrService) {

    this.updateAvailable$ = updates.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      })),
      map(_ => true));
    this.setupPeriodicCheckForUpdates();
    this.setupOnlineCheck();
  }

  update(): void {
    this.updates.activateUpdate().then(() => document.location.reload());
  }

  private setupOnlineCheck() {
    window.addEventListener('load', () => {
      window.addEventListener('online', event => this.handleNetworkChange(event));
      window.addEventListener('offline', event => this.handleNetworkChange(event));
    });
  }

  private handleNetworkChange(event) {
    if (navigator.onLine) {
      this.checkNetworkConnection();
    } else {
      this.toastr.warning('Probeer je wifi uit en aan te zetten', 'Geen verbinding');
    }
  }

  private setupPeriodicCheckForUpdates() {
    if (this.updates.isEnabled) {
      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const everyHour$ = interval(60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everyHour$);

      everySixHoursOnceAppIsStable$.subscribe(() => {
        console.log('Checking for updates');
        return this.updates.checkForUpdate();
      });
    } else {
      console.error('Unable to auto-check for updates, not supported by browser');
    }
  }

  private checkNetworkConnection(): void {
    const conn = (navigator as any).connection;
    if (conn) {
      if (conn.saveData) {
        // saveData read-only property of the NetworkInformation interface
        // returns true if the user has set a reduced data usage option on the user agent.
      }
      const effectiveType = conn.effectiveType;
      if (this.slowConnections.indexOf(effectiveType) > -1) {
        this.toastr.warning('Je verbinding is traag of instabiel, probeer WiFi aan te zetten indien mogelijk', 'Slechte verbinding');
        console.warn('Connection is unstable or slow: ' + effectiveType);
      } else {
        this.toastr.success('Je hebt weer verbinding!');
      }
    }
  }

}
