<a mat-list-item *ngIf="!item.disabled" [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(item)" [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}">
  <mat-icon class="routeIcon">{{item.iconName}}</mat-icon>
  {{item.displayName}}
  <span class="spacer" *ngIf="item.children && item.children.length">
    <span class="spacer"></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</a>
<div class="child-items" *ngIf="expanded">
  <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1"></app-menu-list-item>
</div>
