import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {OpenGroundStockService} from '../open-ground-stock.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OpenGroundStock} from '../openGroundStock';
import {BehaviorSubject, Observable} from 'rxjs';
import {Location} from '@angular/common';
import {OpenGroundStockGrubDialogComponent} from '../vollegrond-rooi-dialog/open-ground-stock-grub-dialog.component';
import {OptionsVrijDialogComponent} from '../../pot/pot-detail/options-vrij-dialog/options-vrij-dialog.component';
import {VoorraadDeleteDialogComponent} from '../../pot/pot-detail/delete-dialog/voorraad-delete-dialog.component';
import {DeleteDialogResult} from '../../pot/pot-detail/delete-dialog/DeleteDialogResult';
import {ChangeVrijDialogComponent} from '../../pot/pot-detail/change-vrij-dialog/change-vrij-dialog.component';
import {map, share} from 'rxjs/operators';
import {ReservedSalesStock} from '../../../reservation/reservedStock';
import {ReservationService} from '../../../reservation/reservation.service';
import {ReleaseAfroepDialogComponent} from '../../pot/pot-detail/release-afroep-dialog/release-afroep-dialog.component';
import {UnitSize} from '../../../common/unitsize-autocomplete-field/unitsize';
import {
  ChangeAllBedNrForOpenGroundStockDialog
} from '../change-all-bed-nr-for-open-ground-dialog/change-all-bed-nr-for-open-ground-stock-dialog';

@Component({
  selector: 'app-open-ground-stock-detail',
  templateUrl: './open-ground-stock-detail.component.html',
  styleUrls: ['./open-ground-stock-detail.component.scss']
})
export class OpenGroundStockDetailComponent implements OnInit {

  private readonly googleSearchUrl = 'https://www.google.com/search?tbm=isch&q=';

  loadingSubject = new BehaviorSubject<boolean>(true);
  isLoading$: Observable<boolean> = this.loadingSubject.asObservable();
  reservation$: Observable<ReservedSalesStock[]>;
  original: OpenGroundStock;
  originalLocation: string;
  error: any;
  item: OpenGroundStock;

  constructor(
    private openGroundStockService: OpenGroundStockService,
    private route: ActivatedRoute,
    private reservationService: ReservationService,
    private _location: Location,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    const key: number = +this.route.snapshot.paramMap.get('id');
    this.openGroundStockService.getById(key)
      .subscribe(v => {
          this.original = v;
          this.item = this.original;
          this.originalLocation = v.location;
          if (this.item.hasReservations) {
            this.reservation$ =
              this.reservationService.getReservedSalesStockByStockId(this.item.id)
                .pipe(share());
          }
          this.loadingSubject.next(false);
        },
        error => {
          this.error = error;
        });
  }

  searchForImages() {
    window.open(this.googleSearchUrl + this.item.plantName, '_blank');
  }

  cancel() {
    this.item = this.original;
    this._location.back();
  }

  applyChanges() {
    this.validateAndSaveChanges().then(openGroundStock => {
      this.item = openGroundStock;
    })
      .catch(err => {
        console.error(err);
        this.snackBar.open('Kon voorraadpartij niet opslaan', 'sluit', {duration: 2000});
      });
  }

  private async validateAndSaveChanges(): Promise<OpenGroundStock> {
    try {
      this.loadingSubject.next(true);
      if (!await this.checkForMoreVoorraadInSameBed()) {
        return Promise.reject();
      }
      if (!await this.checkVrijMoreThanRest()) {
        return Promise.reject();
      }
      this.setLastCountedDate();
      return await this.openGroundStockService.update(this.item).toPromise();
    } catch (error) {
      console.error('Error while saving voorraad', error);
      this.snackBar.open('Kon voorraadpartij niet opslaan', 'sluit', {duration: 2000});
      throw error;
    } finally {
      this.loadingSubject.next(false);
    }
  }

  private async checkForMoreVoorraadInSameBed(): Promise<boolean> {
    if (this.originalLocation !== this.item.location) {
      const moreOfSameVoorraad: OpenGroundStock[] =
        (await this.openGroundStockService.getStockByLocation(this.originalLocation).toPromise())
          .filter(v => v.plantCode === this.item.plantCode)
          .filter(v => v.id !== this.item.id);
      if (moreOfSameVoorraad.length > 0) {
        return this.dialog.open(ChangeAllBedNrForOpenGroundStockDialog, {data: [moreOfSameVoorraad, this.item.location]})
          .afterClosed().toPromise();
      }
    }
    return Promise.resolve(true);
  }

  private checkVrijMoreThanRest(): Promise<boolean> {
    if ((this.item.amountFitForSale || 0) <= (this.item.rest || 0)) {
      return Promise.resolve(true);
    } else {
      return this.dialog.open(ChangeVrijDialogComponent, {data: this.item})
        .afterClosed().pipe(map(i => !!i)).toPromise();
    }
  }

  private setLastCountedDate() {
    this.item.lastInspection = new Date();
  }

  saveAndBack() {
    this.validateAndSaveChanges()
      .then(
        _ => this._location.back());
  }

  async handleReleaseReservationButton(id: number) {
    try {
      if (this.item && this.item.hasReservations) {
        if (await this.askUserToReleaseReservation()) {
          this.loadingSubject.next(true);
          await this.reservationService.releaseSalesReservation(id).toPromise();
          this.snackBar.open('Vrijgegeven', '', {duration: 1000});
          this._location.back();
        }
      }
    } finally {
      this.loadingSubject.next(false);
    }
  }

  askUserToReleaseReservation(): Promise<Boolean> {
    return this.dialog.open(ReleaseAfroepDialogComponent)
      .afterClosed().toPromise();
  }

  calculateRestAndTotal() {
    const originalAmount = this.item.originalAmount || 0;
    const dead = this.item.dead || 0;
    const amountTrashed = this.item.amountTrashed || 0;
    const sold = this.item.sold || 0;
    const amountReserved = this.item.amountReserved || 0;
    const amountReservedForProduction = this.item.amountReservedForProduction || 0;
    const harvestedAmount = this.item.harvestedAmount || 0;
    this.item.rest = originalAmount - dead - sold - amountTrashed - amountReserved - amountReservedForProduction - harvestedAmount;
    this.item.total = originalAmount - dead - sold - amountTrashed - harvestedAmount;
  }

  openVrijOptions(): void {
    this.dialog.open(OptionsVrijDialogComponent, {
      data: this.item,
      disableClose: true
    });
  }

  openDeleteDialog() {
    if (this.item) {
      this.dialog.open(VoorraadDeleteDialogComponent)
        .afterClosed().toPromise().then((result: DeleteDialogResult) => {
        switch (result) {
          case DeleteDialogResult.DOOD:
            this.registerAllDead();
            break;
          case DeleteDialogResult.UNSELLABLE:
            this.registerNotSellable();
            break;
        }
      });
    }
  }

  setCurrentHarvestDate() {
    this.item.harvestDate = new Date();
  }

  async openRooiAllDialog() {
    if (this.item) {
      if (await this.dialog.open(OpenGroundStockGrubDialogComponent, {
        data: this.item
      }).afterClosed().toPromise()) {
        await this.registerAllGerooid();
      }
    }
  }

  private registerAllGerooid() {
    this.item.harvestedAmount = (this.item.harvestedAmount || 0) + this.item.rest;
    this.setCurrentHarvestDate();
    this.saveAndBack();
  }

  private registerNotSellable() {
    this.item.amountReserved = (this.item.amountReserved || 0) + this.item.rest;
    this.item.amountFitForSale = 0;
    this.saveAndBack();
  }

  private registerAllDead() {
    this.item.dead = (this.item.dead || 0) + this.item.rest;
    this.item.amountFitForSale = 0;
    this.saveAndBack();
  }

  private setUnitSize($event: UnitSize) {
    this.item.unitSizeName = $event.name;
    this.item.unitSizeId = $event.id;
  }
}
