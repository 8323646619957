import {ComponentRef, Directive} from '@angular/core';
import {ActivatedRoute, RouterOutlet} from '@angular/router';

export interface Attachable {
    onAttach(ref: ComponentRef<any>, activatedRoute: ActivatedRoute): void;
}

export interface Detachable {
    onDetach(): void;
}

@Directive({
    /* tslint:disable-next-line:directive-selector */
    selector: 'app-router-outlet',
})
export class AppRouterOutletDirective extends RouterOutlet {

    isDetachable(object: Object): object is Detachable {
        return (<Detachable>object).onDetach !== undefined;
    }

    isAttachable(object: Object): object is Attachable {
        return (<Attachable>object).onAttach !== undefined;
    }

    detach(): ComponentRef<any> {
        if (this.component && this.isDetachable(this.component)) {
            try {
                (this.component as Detachable).onDetach();
            } catch (err) {
                console.error('Unable to perform onDetach due to error!');
                console.error(err);
            }
        }
        return super.detach();
    }

    attach(ref: ComponentRef<any>, activatedRoute: ActivatedRoute): void {
        super.attach(ref, activatedRoute);
        if (ref.instance && this.isAttachable(ref.instance)) {
            try {
                ref.instance.onAttach(ref, activatedRoute);
            } catch (err) {
                console.error('Unable to perform onAttach due to error!');
                console.error(err);
            }
        }
    }
}

