import {Injectable} from '@angular/core';
import {ProductionJob} from '../../production-job';
import {Observable, of} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {first} from 'rxjs/operators';
import {ProductionJobService} from '../../production-job.service';


@Injectable()
export class ProductionJobResolverService implements Resolve<Observable<ProductionJob>> {
  constructor(readonly productionJobService: ProductionJobService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<ProductionJob> {
    const productionJobId = route?.queryParams?.productionJobId;
    if (!productionJobId) {
      return of(null);
    }
    return this.productionJobService.findActiveProductionJobById(+productionJobId).pipe(first());
  }
}
