export enum SelectedLocation {
  All = 'Alle',
  Borculo = 'Borculo',
  Enschede = 'Enschede',
  Nordkirchen = 'Nordkirchen',
  Needseweg = 'Needseweg',
  Novatec = 'Novatec',
  Bergsma = 'Bergsma',
  Meddo = 'Meddo',
  Gregg = 'Gregg'
}
