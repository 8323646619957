import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SalesOrderItem} from '../../sales-order-item';
import {LabelPrintService} from './label-print/label-print.service';
import {ToastrService} from 'ngx-toastr';
import {PrinterLocation} from './PrinterLocation';

@Component({
  selector: 'app-printing-dialog',
  templateUrl: './printing-dialog.component.html',
  styleUrls: ['./printing-dialog.component.scss']
})
export class PrintingDialogComponent implements OnInit {

  printerLocation: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SalesOrderItem,
    private dialogRef: MatDialogRef<PrintingDialogComponent, PrinterLocation>,
    private _dialog: MatDialog,
    private labelPrintService: LabelPrintService,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit() {
  }

  cancel(): void {
    this.dialogRef.close();
  }

  async onSubmitClick(printer, salesOrderItemId) {
    try {
      console.log(printer);
      await this.labelPrintService.printLabel(printer, salesOrderItemId)
        .toPromise();
      this.toastrService.success('Succesvol geprint');
      this.dialogRef.close();
    } catch (e) {
      console.error(e);
    }
  }

  isDisabled(): boolean {
    return !this.printerLocation || !this.data.neededLabels ||
      this.data.neededLabels <= 0;
  }
}



