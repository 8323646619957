import {Injectable} from '@angular/core';
import {BaseService} from '../../common/base.service';
import {Observable} from 'rxjs';
import {ProductionJob} from '../pot/production-job';
import {catchError} from 'rxjs/operators';
import {CreateStockWithProductionJob} from '../pot/pot-jobs/create-stock-dialog/create-stock-with-production-job';


@Injectable()
export class OpenGroundProductionJobService extends BaseService {

  private url = this.baseUrl + '/open-ground-production-job';

  getAllProductionJobsForWeek(fromDate: string, untilDate: string): Observable<ProductionJob[]> {
    const dateObject = {fromDate: fromDate, untilDate: untilDate};
    return this.http.post<ProductionJob[]>(this.url + '/week', dateObject)
      .pipe(catchError(this.handleError('ophalen van alle oppot opdrachten', [])));
  }

  getAllProductionJobs(): Observable<ProductionJob[]> {
    return this.http.post<ProductionJob[]>(this.url + '/week', null)
      .pipe(catchError(this.handleError('ophalen van alle oppot opdrachten', [])));
  }

  createNewStockEntity(newStockEntity: CreateStockWithProductionJob) {
    return this.http.post<ProductionJob>(this.url + '/create-stock', newStockEntity)
      .pipe(catchError(this.handleError('Aanmaken van stock', [])));
  }

}
