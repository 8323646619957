import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {PotStock} from './pot';
import {BaseService} from '../../common/base.service';
import {LocationWithVoorraad} from './location-with-voorraad';

@Injectable()
export class PotService extends BaseService {

  private voorraadUrl = this.baseUrl + '/pot-stock';

  findStockByCodeOrName(searchTerm: string): Observable<PotStock[]> {
    return this.http.get<PotStock[]>(this.voorraadUrl + '/search?terms=' + encodeURIComponent(searchTerm))
      .pipe(catchError(this.handleError('zoeken van pot-voorraadpartijen', <PotStock[]>[],
        'Fout bij ophalen van pot-voorraad waarbij zoekterm ' + searchTerm + ' deel uitmaakt van naam of code')));
  }

  getStockById(id: number): Observable<PotStock> {
    return this.http.get<PotStock>(this.voorraadUrl + '/' + id)
      .pipe(catchError(this.handleError('ophalen van pot-voorraadpartij', undefined,
        'Fout bij ophalen van pot-voorraad met id ' + id)));
  }

  getAllStock(): Observable<PotStock[]> {
    return this.http.get<PotStock[]>(this.voorraadUrl)
      .pipe(catchError(this.handleError('ophalen van alle pot-voorraadpartijen', [])));
  }

  getBedNrs(): Observable<string[]> {
    return this.http.get<string[]>(this.voorraadUrl + '/locations')
      .pipe(catchError(this.handleError('ophalen van bednummers', [])));
  }

  getStockByBedNr(bedNr: string): Observable<PotStock[]> {
    return this.http.get<PotStock[]>(this.baseUrl + '/stock/location/search?terms=' + encodeURIComponent(bedNr))
      .pipe(catchError(this.handleError('ophalen van pot-voorraadpartij aan de hand van bednummer', [])));
  }

  getPotStockByBedNr(bedNr: string): Observable<PotStock[]> {
    return this.http.get<PotStock[]>(this.voorraadUrl + '/location/search?terms=' + encodeURIComponent(bedNr))
      .pipe(catchError(this.handleError('ophalen van pot-voorraadpartij aan de hand van bednummer', [])));
  }


  updateStock(voorraad: PotStock): Observable<PotStock> {
    return this.http.put<PotStock>(this.voorraadUrl + '/' + voorraad.id, voorraad);
  }

  deleteStock(voorraad: PotStock): Observable<void> {
    return this.http.delete<void>(this.voorraadUrl + '/' + voorraad.id);
  }

  releaseAfroep(id: number): Observable<PotStock> {
    return this.http.delete<void>(this.baseUrl + '/reservedstock/' + id)
      .pipe(catchError(this.handleError('vrijgeven van voorraad', undefined, '')));
  }

  splitStock(stockId: number, splitAmount: number, amountFitForSale: number, location?: string, remark?: string, version?: number,
             reservedStockIds?: number[]) {
    return this.http.patch<PotStock>(
      `${this.baseUrl}/stock/split`,
      {
        stockId: stockId,
        splitAmount: splitAmount,
        amountFitForSale: amountFitForSale,
        location: location,
        remark: remark,
        version: version,
        reservedStockIds: reservedStockIds,
      }
    );
  }

  createStock(stock: PotStock): Observable<PotStock> {
    return this.http.post<PotStock>(this.voorraadUrl, stock);
  }

  countLocation(location: string): Observable<PotStock[]> {
    return this.http.put<PotStock[]>(
      `${this.baseUrl}/stock/location/${location}`,
      {}
    );
  }

  countPriorityLocation(locationWithStocks: LocationWithVoorraad): Observable<LocationWithVoorraad> {
    return this.http.put<LocationWithVoorraad>(
      `${this.baseUrl}/pot-stock/priority-location`, locationWithStocks);
  }

  getAllStockRequireCounting(): Observable<LocationWithVoorraad[]> {
    return this.http.get<LocationWithVoorraad[]>(this.voorraadUrl + '/locations/counting')
      .pipe(catchError(this.handleError('ophalen van alle pot-voorraadpartijen', [])));
  }

  getAllStockToReceiveLocation(): Observable<PotStock[]> {
    return this.http.get<PotStock[]>(this.voorraadUrl + '/new-stock')
      .pipe(catchError(this.handleError('ophalen van alle pot-voorraadpartijen die nog geen juiste locatie hebben', [])));
  }

  getAllStockToBeCounted(): Observable<LocationWithVoorraad[]> {
    return this.http.get<LocationWithVoorraad[]>(this.voorraadUrl + '/controlled-count')
      .pipe(catchError(this.handleError('ophalen van alle pot-voorraadpartijen die geteld moeten worden', [])));
  }

  findHistoricStockByCodeOrNameOrLocation(searchTerm: string): Observable<PotStock[]> {
    return this.http.get<PotStock[]>(this.voorraadUrl + '/search/historic?terms=' + encodeURIComponent(searchTerm))
      .pipe(catchError(this.handleError('zoeken van historische pot-voorraadpartijen', <PotStock[]>[],
        'Fout bij ophalen van historische pot-voorraad waarbij zoekterm ' + searchTerm + ' deel uitmaakt van naam, code of locatie')));
  }
}
