<div id="buttonBar">
  <div id="toggle-container">
    <mat-slide-toggle [(ngModel)]="onlyWithComments" (change)="updateTableItems()">Alleen met commentaar</mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="hideChecked" (change)="updateTableItems()">Verstop afgecheckt</mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="hideInkoop" (change)="updateTableItems()">Verstop inkoop</mat-slide-toggle>
  </div>
  <app-location-selector></app-location-selector>
  <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>
</div>
<div id="table-container" #scrollContainer mat-elevation-z8>
  <div class="loading-shade" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
  </div>
  <table id="order-item-table" mat-table [dataSource]="orderItemDataSource" matSort matSortActive="name"
    matSortDirection="asc" [hidden]="orderItemDataSource?.data.length == 0">
    <ng-container matColumnDef="checked">
      <th mat-header-cell *matHeaderCellDef>Rest</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <button class="btn-check-all-hoog" mat-button color="primary" (click)="checkRemainingHigh(element)">Hoog</button>
          <button mat-button color="primary" (click)="checkRemainingLow(element)">Laag</button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Potten </th>
      <td mat-cell *matCellDef="let element"> {{element.deliverAmount}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Naam </th>
      <td mat-cell *matCellDef="let element"> {{element.articleName}} </td>
    </ng-container>

    <ng-container matColumnDef="bedNr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Bed </th>
      <td mat-cell *matCellDef="let element"> {{element.location}} </td>
    </ng-container>

    <ng-container matColumnDef="vml">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Laag </th>
      <td mat-cell *matCellDef="let element">
        <app-counter [count]="element.scannedVml" (countChange)="vmlChanged($event, element)" [minimum]="0">
        </app-counter>
      </td>
    </ng-container>

    <ng-container matColumnDef="vmh">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Hoog </th>
      <td mat-cell *matCellDef="let element">
        <app-counter [count]="element.scannedVmh" (countChange)="vmhChanged($event, element)" [minimum]="0">
        </app-counter>
      </td>
    </ng-container>

    <ng-container matColumnDef="mergedBoxes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Samen </th>
      <td mat-cell *matCellDef="let element">
        <app-counter [count]="element.scannedMerged" (countChange)="mergedBoxesChanged($event, element)" [minimum]="0">
        </app-counter>
      </td>
    </ng-container>

    <ng-container matColumnDef="neededLabels">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Labels </th>
      <td mat-cell *matCellDef="let element">{{element.neededLabels}}</td>
    </ng-container>

    <ng-container matColumnDef="bonnaam">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Bon</th>
      <td mat-cell *matCellDef="let element">{{element.bonnaam}}</td>
    </ng-container>

    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Commentaar </th>
      <td mat-cell *matCellDef="let element">
        {{element.comments}}
        <button mat-icon-button color="primary" (click)="openCommentsDialog(element)">
          <mat-icon *ngIf="!element.comments">note_add</mat-icon>
          <mat-icon *ngIf="element.comments">edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="opm">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Opmerking </th>
      <td mat-cell *matCellDef="let element"> {{element.remarks}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" [class.is-checked]="element.allLabelsScanned"></tr>
  </table>
  <div id="no-order-items" *ngIf="orderItemDataSource?.data.length == 0 && !(loading$ | async)">
    Er staan geen regels klaar voor deze bon of locatie
  </div>
</div>
