import {SalesOrderItem} from '../sales-order-item';

export class PaklijstItem extends SalesOrderItem {

  bonnaam: string;

  constructor(item: SalesOrderItem, bonnaam: string) {
    super();
    this.id = item.id;
    this.version = item.version;
    this.articleId = item.articleId;
    this.salesOrderId = item.salesOrderId;
    this.stockId = item.stockId;
    this.supplierId = item.supplierId;
    this.reservationItemId = item.reservationItemId;
    this.unitSizeId = item.unitSizeId;
    this.articleName = item.articleName;
    this.orderAmount = item.orderAmount;
    this.deliverAmount = item.deliverAmount;
    this.salesPrice = item.salesPrice;
    this.buyPrice = item.buyPrice;
    this.location = item.location;
    this.confirmedToCustomer = item.confirmedToCustomer;
    this.orderedFromSupplier = item.orderedFromSupplier;
    this.isSearched = item.isSearched;
    this.remarks = item.remarks;
    this.comments = item.comments;
    this.isSteekLabelPrinted = item.isSteekLabelPrinted;
    this.isPakLabelPrinted = item.isPakLabelPrinted;
    this.neededLabels = item.neededLabels;
    this.scannedVml = item.scannedVml;
    this.scannedVmh = item.scannedVmh;
    this.scannedMerged = item.scannedMerged;
    this.voorraadnr = item.voorraadnr;
    this.voorraadvnr = item.voorraadvnr;
    this.dBaseAfroepNr = item.dBaseAfroepNr;
    this.mustBeChecked = item.mustBeChecked;
    this.allLabelsScanned = item.allLabelsScanned;
    this.bonnaam = bonnaam;
  }

}
