import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appAddHyphenAfterTwoDigits]'
})
export class AddHyphenAfterTwoDigitsDirective {

  constructor() {
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: any) {
    const input = event.target;
    let value = input.value;
    if (value.length === 2 && event.key !== 'Backspace') {
      value += '-';
    //   If length is 3 and event is backspace: remove the 3rd character too.
    } else if (value.length === 3 && event.key === 'Backspace') {
      value = value.slice(0, -1);
    }
    input.value = value;
  }
}
