import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {ProductionJob} from '../../production-job';
import {PotProductionJobService} from '../../potting-production-job.service';
import {CreateStockWithProductionJob} from './create-stock-with-production-job';

@Component({
  selector: 'app-printing-dialog',
  templateUrl: './create-stock-dialog.component.html',
  styleUrls: ['./create-stock-dialog.component.scss']
})
export class CreateStockDialogComponent {

  originalAmount: number;
  remarks: string;
  location: string;
  productionDate: Date;
  bookAutomaticallyFromStock: boolean;
  productionJob: ProductionJob;
  allowMarkingAsProduced: boolean;
  isLoading: boolean;
  plantName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateStockDialogComponent>,
    private potProductionJobService: PotProductionJobService,
    private toastrService: ToastrService
  ) {
    this.plantName = data.plantName;
    this.productionJob = data.productionJob;
    this.allowMarkingAsProduced = data.allowMarkingAsProduced;
    this.originalAmount = this.productionJob.orderedAmount;
    this.remarks = this.productionJob.remarks;
    this.bookAutomaticallyFromStock = this.productionJob.hasReservedStock;
    this.productionDate = new Date();
    this.isLoading = false;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  async onSubmitClickCreateStock() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    const newStockEntity = new CreateStockWithProductionJob(this.originalAmount,
      this.location,
      this.remarks,
      this.productionJob.id,
      this.productionDate,
      this.originalAmount > this.productionJob.totalReservedAmount ? this.productionJob.totalReservedAmount : this.originalAmount,
      this.originalAmount < this.productionJob.totalReservedAmount ? this.productionJob.totalReservedAmount - this.originalAmount : 0,
      this.bookAutomaticallyFromStock
    );
    try {
      await this.potProductionJobService.createNewStockEntity(newStockEntity)
        .toPromise()
        .then(() => {
          this.toastrService.success('Nieuwe voorraad succesvol aangemaakt!');
        })
        .catch(err => {
          this.toastrService.error('Er is iets misgegaan');
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
          this.dialogRef.close();
        });
    } catch (e) {
      this.toastrService.error('Er is iets mis gegaan!');
      console.error(e);
      this.isLoading = false;
    }
  }

  isDisabled() {
    return !this.originalAmount || !this.location;
  }

  async onSubmitClickMarkAsProduced() {
    this.isLoading = true;
    await this.potProductionJobService.markProductionJobAsProduced(this.productionJob.id)
      .toPromise()
      .then(() => this.toastrService.success('Productie opdracht als geproduceerd gemarkeerd!'))
      .catch(err => {
        console.error(err);
        this.toastrService.error('Er is iets misgegaan');
      })
      .finally(() => {
        this.isLoading = false;
        this.dialogRef.close();
      });
  }
}
