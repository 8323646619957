import {BaseService} from '../common/base.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Plant} from './plant';
import {catchError} from 'rxjs/operators';

@Injectable()
export class PlantService extends BaseService {
  private plantUrl = this.baseUrl + '/plants';

  checkIfCodeIsValid(plantCode: string): Observable<boolean> {
    return this.http.get<boolean>(this.plantUrl + '/exists?code=' + encodeURIComponent(plantCode));
  }

  fetchPlantsByCodeOrName(codeOrName: string): Observable<Plant[]> {
    return this.http.get<Plant[]>(this.plantUrl + '/search?searchTerm=' + encodeURIComponent(codeOrName))
      .pipe(catchError(this.handleError('fetching plants by code or name', [])));
  }
}
