import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SalesOrderService} from '../sales-order.service';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {BehaviorSubject, Subscription} from 'rxjs';
import {PaklijstItem} from './paklijst-item';
import {OrderDetailOpmDialogComponent} from '../order-detail/order-detail-opm-dialog/order-detail-opm-dialog.component';
import {LocationService} from '../../_services/location.service';

@Component({
  selector: 'app-checklist-order-item-overview',
  templateUrl: './sales-order-item-overview.component.html',
  styleUrls: ['./sales-order-item-overview.component.scss']
})
export class SalesOrderItemOverviewComponent implements OnInit, AfterViewInit, OnDestroy {

  orderItems: PaklijstItem[];
  orderItemDataSource = new MatTableDataSource<PaklijstItem>();
  displayedColumns: string[] = ['checked', 'amount', 'bonnaam', 'name', 'bedNr',
    'vml', 'vmh', 'mergedBoxes', 'neededLabels', 'comments', 'opm'];
  loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  subscriptions = new Subscription();
  onlyWithComments = false;
  hideChecked = true;
  hideInkoop = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private checklistService: SalesOrderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.orderItemDataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Regels op pagina:';
    this.paginator._intl.getRangeLabel = this.paginatorRangeLabelIntl();
    this.orderItemDataSource.sortingDataAccessor = (item, property) => {
      if (property === 'location') {
        return item.location ? item.location.toLocaleLowerCase() : '';
      } else {
        return item[property];
      }
    };
    this.refreshCheckListItems().then(_ => {
      this.subscriptions.add(this.locationService.observeSelectedLocation().subscribe(() => {
        if (this.orderItems) {
          this.updateTableItems();
        }
      }));
    });
  }

  ngAfterViewInit() {
    this.orderItemDataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateTableItems() {
    this.orderItemDataSource.data = this.orderItems
      .filter(item => this.locationService.isBedNrFromSelectedLocation(item.location))
      .filter(item => item.comments || !this.onlyWithComments)
      .filter(item => !this.hideChecked || !item.allLabelsScanned)
      .filter(item => !this.hideInkoop || !this.itemIsInkoop(item));
  }

  locationToggleChanged(event: MatButtonToggleChange) {
    this.locationService.setSelectedLocation(event.value);
    this.updateTableItems();
  }

  async refreshCheckListItems() {
    this.loadingSubject.next(true);
    try {
      const orders = await this.checklistService.getSalesOrdersForPakdag().toPromise();
      this.orderItems = [].concat.apply([], orders.map(o => o.items.map(i => new PaklijstItem(i, o.name))));
      this.updateTableItems();
    } catch (error) {
      console.error('Error while getting checklist items', error);
    } finally {
      this.loadingSubject.next(false);
    }
  }

  checkRemainingLow(item: PaklijstItem) {
    if (item.neededLabels > (item.scannedVml + item.scannedVmh + item.scannedMerged)) {
      item.scannedVml = item.neededLabels - (item.scannedVmh + item.scannedMerged);
      item.allLabelsScanned = true;
      this.updateOrderItem(item);
    }
  }

  checkRemainingHigh(item: PaklijstItem) {
    if (item.neededLabels > (item.scannedVml + item.scannedVmh + item.scannedMerged)) {
      item.scannedVmh = item.neededLabels - (item.scannedVml + item.scannedMerged);
      item.allLabelsScanned = true;
      this.updateOrderItem(item);
    }
  }

  vmlChanged(newValue: number, item: PaklijstItem) {
    item.scannedVml = newValue;
    this.checkIfComplete(item);
    this.updateOrderItem(item);
  }

  vmhChanged(newValue: number, item: PaklijstItem) {
    item.scannedVmh = newValue;
    this.checkIfComplete(item);
    this.updateOrderItem(item);
  }

  mergedBoxesChanged(newValue: number, item: PaklijstItem) {
    item.scannedMerged = newValue;
    this.checkIfComplete(item);
    this.updateOrderItem(item);
  }

  openCommentsDialog(item: PaklijstItem): void {
    const dialogRef = this.dialog.open(OrderDetailOpmDialogComponent, {
      width: '250px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateOrderItem(result);
      }
    });
  }

  private checkIfComplete(item: PaklijstItem) {
    const currentChecked = item.allLabelsScanned;
    item.allLabelsScanned = item.scannedVml + item.scannedVmh + item.scannedMerged === item.neededLabels;
    if (currentChecked !== item.allLabelsScanned) {
      this.updateTableItems();
    }
  }

  private async updateOrderItem(item: PaklijstItem) {
    this.loadingSubject.next(true);
    try {
      const updatedItem = await this.checklistService.updateSalesOrderItem(item).toPromise();
      item.version = updatedItem.version;
    } catch (error) {
      console.error('Unable to save CheckListItem:\n' + error);
      this.snackBar.open('Fout bij opslaan! Probeer het nog eens', '', {duration: 1000});
    } finally {
      this.updateTableItems();
      this.loadingSubject.next(false);
    }
  }

  private paginatorRangeLabelIntl(): (page: number, pageSize: number, length: number) => string {
    return (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 van ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} van ${length}`;
    };
  }

  private itemIsInkoop(item: PaklijstItem) {
    if (!item.location) {
      return false;
    }
    const location = item.location.toLocaleLowerCase();
    return location.startsWith('i') || location.startsWith('d');
  }

}
