import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {OpenGroundProductionJobService} from '../../open-ground-production-job.service';
import {ProductionJob} from '../../../pot/production-job';
import {CreateStockWithProductionJob} from '../../../pot/pot-jobs/create-stock-dialog/create-stock-with-production-job';

@Component({
  selector: 'app-printing-dialog',
  templateUrl: './create-open-ground-stock-dialog.component.html',
  styleUrls: ['./create-open-ground-stock-dialog.component.scss']
})
export class CreateOpenGroundStockDialogComponent {

  originalAmount: number;
  inputMaterialAmount: number;
  inputMaterialDeadAmount: number;
  remarks: string;
  location: string;
  productionDate: Date;
  bookAutomaticallyFromStock: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProductionJob,
    private dialogRef: MatDialogRef<CreateOpenGroundStockDialogComponent>,
    private openGroundProductionJobService: OpenGroundProductionJobService,
    private toastrService: ToastrService
  ) {
    this.originalAmount = data.orderedAmount;
    this.remarks = data.remarks;
    this.inputMaterialAmount = data.orderedAmount;
    this.inputMaterialDeadAmount = 0;
    this.bookAutomaticallyFromStock = data.hasReservedStock;
    this.productionDate = new Date();
    this.location = localStorage.getItem('last-open-ground-location') || '';
    this.productionDate = new Date(localStorage.getItem('last-production-date')) || new Date();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  async onSubmitClick() {
    const newStockEntity = new CreateStockWithProductionJob(this.originalAmount,
      this.location,
      this.remarks,
      this.data.id,
      this.productionDate,
      this.inputMaterialAmount,
      this.inputMaterialAmount - this.originalAmount >= 0 ? this.inputMaterialAmount - this.originalAmount : 0,
      this.bookAutomaticallyFromStock
    );
    try {
      await this.openGroundProductionJobService.createNewStockEntity(newStockEntity)
        .toPromise();
      this.toastrService.success('Nieuwe voorraad succesvol aangemaakt!');
      this.dialogRef.close();
    } catch (e) {
      this.toastrService.error('Er is iets mis gegaan!');
      console.error(e);
    }
  }

  isDisabled() {
    return !this.originalAmount || !this.location;
  }

  saveLocationToLocalStorage(location: string) {
    if (location) {
      localStorage.setItem('last-open-ground-location', location);
    }
  }

  saveDateToLocalStorage(date: Date) {
    if (date) {
      localStorage.setItem('last-production-date', date.toISOString());
    }
  }
}
