import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild} from '@angular/core';
import Quagga, {QuaggaJSConfigObject, QuaggaJSResultObject} from '@ericblade/quagga2';
import {DEFAULT_CONFIG} from './scanner.config';
import {Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements OnChanges, OnDestroy {
  private readonly destroy$ = new Subject();


  @Input() type: string | string[];
  @Input() deviceId: string;

  @Output() scanSuccess = new EventEmitter<QuaggaJSResultObject>();
  @Output() started = new EventEmitter<boolean>();

  @ViewChild('scanner', { static: true }) scanner: ElementRef<HTMLDivElement>;

  private _started = false;
  private readonly CERTAINTY = 0.9;
  private scannedSubject: Subject<QuaggaJSResultObject> = new Subject();

  constructor() {
    this.scannedSubject
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
      )
      .subscribe(result => this.scanSuccess.emit(result));
  }

  get isStarted() {
    return this._started;
  }

  private configQuagga: QuaggaJSConfigObject = DEFAULT_CONFIG;

  ngOnDestroy(): void {
    this.stop();
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.restart();
  }

  private _init() {
    return new Promise<void>((resolve, reject) => {
      Quagga.onProcessed((result) => this.onProcessed(result));

      Quagga.onDetected((result) => this.onDetected(result));

      this.configQuagga.inputStream.target = this.scanner.nativeElement;

      if (this.deviceId) {
        this.configQuagga.inputStream.constraints.deviceId = this.deviceId;
      }

      Quagga.init(this.configQuagga, (err) => {
        if (err) {
          console.error(err);
          return reject(err);
        }

        resolve();
      });
    });
  }

  async start() {
    if (!this._started) {
      await this._init();
      Quagga.start();

      this._started = true;
      this.started.next(true);
    }
  }

  pause() {
    if (this._started) {
      this._started = false;
      this.started.next(false);
      Quagga.pause();
    }
  }

  stop() {
    Quagga.stop();
    this._started = false;
    this.started.next(false);
  }

  async restart() {
    this.stop();
    await this.start();
  }

  onProcessed(result: QuaggaJSResultObject): void {
    const drawingCtx = Quagga.canvas.ctx.overlay,
      drawingCanvas = Quagga.canvas.dom.overlay;

    if (result) {
      if (result.boxes) {
        drawingCtx.clearRect(0, 0,
          +drawingCanvas.getAttribute('width'),
          +drawingCanvas.getAttribute('height'));
        result.boxes
          .filter(box => box !== result.box)
          .forEach(box => Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: 'green', lineWidth: 2 }));
      }

      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: '#00F', lineWidth: 2 });
      }

      if (result.codeResult && result.codeResult.code) {
        Quagga.ImageDebug.drawPath(result.line, {
          x: 'x',
          y: 'y',
        }, drawingCtx, {
          color: 'red',
          lineWidth: 3,
        });
      }

    }
  }

  onDetected(result: QuaggaJSResultObject) {
    if (this.getMedianOfCodeErrors(result) < (1 - this.CERTAINTY)) {
      this.scannedSubject.next(result);
    }
  }

  private getMedianOfCodeErrors(result: QuaggaJSResultObject): number {
    const errors = result.codeResult.decodedCodes
      .filter(x => x.error !== undefined)
      .map(x => x.error);
    return this.getMedian(errors);
  }

  private getMedian(arr: number[]): number {
    arr.sort((a, b) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1) {
      return arr[half];
    }
    return (arr[half - 1] + arr[half]) / 2;
  }

}
