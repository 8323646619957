<h2 mat-dialog-title>Reservering vrijgeven</h2>
<p class="warning-text mat-body-1">Dit zal de voorraadpartij op afroep vrijgeven, weet je het zeker?</p>
<mat-dialog-actions>
    <button mat-raised-button (click)="cancel()">
        <i class="material-icons" aria-label="Annuleren">backspace</i>
        Annuleren
    </button>
    <button mat-raised-button color="warn" (click)="confirmRelease()">
        <i class="material-icons" aria-label="release">lock_open</i>
        Vrijgeven
    </button>
</mat-dialog-actions>
