import {MatDialogRef} from '@angular/material/dialog';
import {Component} from '@angular/core';
import {DeleteDialogResult} from './DeleteDialogResult';

@Component({
  selector: 'app-voorraad-delete-dialog',
  templateUrl: './voorraad-delete-dialog.component.html',
  styleUrls: ['./voorraad-delete-dialog.component.scss']
})
export class VoorraadDeleteDialogComponent {

  bookingMethod: string;

  constructor(public dialogRef: MatDialogRef<VoorraadDeleteDialogComponent, DeleteDialogResult>) {
  }

  onSubmitClick(): void {
    if (this.bookingMethod === 'dead') {
      this.dialogRef.close(DeleteDialogResult.DOOD);
    } else if (this.bookingMethod === 'unsellable') {
      this.dialogRef.close(DeleteDialogResult.UNSELLABLE);
    } else {
      this.dialogRef.close();
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
