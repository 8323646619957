import {Injectable} from '@angular/core';
import {BaseService} from '../common/base.service';
import {ReservedSalesStock} from './reservedStock';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ReservedProductionStock} from './reservedStock';

@Injectable()
export class ReservationService extends BaseService {


  getReservedSalesStockByStockId(stockId: number): Observable<ReservedSalesStock[]> {
    return this.http.get<ReservedSalesStock[]>(`${this.baseUrl}/reservedstock?stockId=${stockId}`);
  }

  releaseSalesReservation(id: number) {
    return this.http.delete<void>(this.baseUrl + '/reservedstock/' + id)
      .pipe(catchError(this.handleError('vrijgeven van voorraad', undefined, '')));
  }

  releaseProductionReservation(id: number) {
    return this.http.delete<void>(this.baseUrl + '/reserved-stock/production/' + id)
      .pipe(catchError(this.handleError('vrijgeven van voorraad', undefined, '')));
  }

  getReservedProductionStockByStockId(stockId: number) {
    return this.http.get<ReservedProductionStock[]>(`${this.baseUrl}/reserved-stock/production?stockId=${stockId}`);
  }
}
