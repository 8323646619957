import {Component, OnInit} from '@angular/core';
import {SalesOrderService} from '../checklist/sales-order.service';
import {faBarcode} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-portaal',
  templateUrl: './portaal.component.html',
  styleUrls: ['./portaal.component.scss']
})
export class PortaalComponent implements OnInit {

  constructor(
    private checklistService: SalesOrderService
  ) {
  }

  ngOnInit() {
  }

  downloadChecklistReport() {
    this.checklistService.downloadReport()
      .subscribe(report => {

        const nav = (window.navigator as any);

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (nav.msSaveOrOpenBlob) {
          nav.msSaveOrOpenBlob(report);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(report);

        const link = document.createElement('a');
        link.href = data;
        link.download = 'rapport.csv';
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });
  }

    protected readonly faBarcode = faBarcode;
}
