<h2 mat-dialog-title>Fout bij opslaan</h2>
<p>Deze regel is ondertussen al door iemand anders aangepast. Wil je de pagina herladen?</p>
<p class="warning-text mat-body-1">Je wijziging zal hierbij verloren gaan!</p>
<mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">
    <i class="material-icons" aria-label="Annuleren">backspace</i>
    Annuleren
  </button>
  <button mat-raised-button color="warn" (click)="confirmReload()">
    <i class="material-icons" aria-label="release">sync</i>
    Herladen
  </button>
</mat-dialog-actions>
