<h2 mat-dialog-title>Verplaatsen</h2>
<p><b>{{amount}}</b> andere {{plantName}} op <b>{{fromBednr}}</b> gevonden.</p>
<p>Wil je deze allemaal verplaatsen naar <b>{{toBednr}}</b>?</p>
<div id="save-information">
  <mat-progress-bar *ngIf="(saving$ | async)" mode="determinate" [value]="progressBarValue"></mat-progress-bar>
  <p class="error" *ngIf="errorMessage">{{errorMessage}}</p>
  <p *ngIf="errorMessage">Wil je deze nogmaals proberen?</p>
</div>
<mat-dialog-actions>
  <button *ngIf="!failed" mat-raised-button (click)="cancel()">
    <i class="material-icons" aria-label="Wijzigingen in voorraad annuleren">backspace</i>
    Annuleer
  </button>
  <button mat-raised-button (click)="answerNo()" [disabled]="(saving$ | async)">Nee</button>
  <button mat-raised-button color="primary" (click)="answerYes()" [disabled]="(saving$ | async)">
    Ja
  </button>
</mat-dialog-actions>