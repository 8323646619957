import {Directive, ElementRef, ViewChild} from '@angular/core';
import {Attachable, Detachable} from '../routing/AppRouterOutletDirective';

export interface ScrollContainer extends Attachable, Detachable { }

type Constructor<T> = new(...args: any[]) => T;

export function WithScrollContainer<T extends Constructor<object>>(Base: T = (class { } as any)) {
    @Directive()
class ScrollContainerMixin extends Base implements ScrollContainer {

        @ViewChild('scrollContainer') scrollContainer: ElementRef;
        scrollPosition: number;

        public async onAttach() {
            if (this.scrollPosition) {
                this.scrollContainer.nativeElement.scrollTo(0, this.scrollPosition);
            }
        }

        public async onDetach() {
            if (this.scrollContainer) {
                this.scrollPosition = this.scrollContainer.nativeElement.scrollTop;
            }
        }
    }

    return ScrollContainerMixin;
}
