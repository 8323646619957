import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {User} from '../_models/user';
import {Observable} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  private readonly LOCAL_STORAGE_KEY_CURRENT_USER = 'currentUser';
  private jwtHelper: JwtHelperService;

  constructor(private http: HttpClient) {
    this.jwtHelper = new JwtHelperService();
  }

  isAuthenticated(): boolean {
    const currentUser: User = this.getUser();
    return currentUser && !this.jwtHelper.isTokenExpired(currentUser.token);
  }

  login(username: string, password: string): Observable<User> {
    const url = `${environment.apiUrl}/login`;
    const body = {username, password};
    return this.http.post<any>(url, body, {observe: 'response'})
      .pipe(map(response => {
        const user = response.body as User || new User();
        user.token = response.headers.get('Authorization');
        if (user && user.token) {
          this.setUser(user);
        }
        return user;
      }));
  }

  private setUser(user: User) {
    localStorage.setItem(this.LOCAL_STORAGE_KEY_CURRENT_USER, JSON.stringify(user));
  }

  private getUser(): User {
    return JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY_CURRENT_USER));
  }

  getUserName(): string {
    if (this.getUser() == null) { return null; }
    return this.jwtHelper.decodeToken(this.getUser().token).sub;
  }

  logout() {
    localStorage.removeItem(this.LOCAL_STORAGE_KEY_CURRENT_USER);
  }
}
