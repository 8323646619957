import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UnitsizeService} from './unitsize.service';
import {UnitSize} from './unitsize';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-unitsize-autocomplete-field',
  templateUrl: './unitsize-autocomplete-field.component.html',
  styleUrls: ['./unitsize-autocomplete-field.component.scss']
})
export class UnitsizeAutocompleteFieldComponent implements OnInit {
  unitSizeControl = new FormControl();
  unitSizes: UnitSize[] = [];
  filteredUnitSizes: Observable<UnitSize[]>;

  @Input() preFilledUnitSizeId: number;
  @Output() unitSizeChange = new EventEmitter<UnitSize>();

  constructor(private unitSizeService: UnitsizeService) {
  }

  ngOnInit() {
    this.fetchUnitSizes();
    this.unitSizeControl.valueChanges.subscribe((value) => {
      this.filterOptions(value);
    });

    this.filteredUnitSizes = this.unitSizeControl.valueChanges.pipe(
      startWith(''),
      map((value) => (value ? this.filterOptions(value) : this.unitSizes.slice()))
    );
  }

  async fetchUnitSizes() {
    this.unitSizeService.findAllUnitSizes().subscribe((response: UnitSize[]) => {
      this.unitSizes = response;
      if (this.preFilledUnitSizeId) {
        const selectedUnitSize = this.unitSizes.find(unitSize => unitSize.id === this.preFilledUnitSizeId);
        this.unitSizeControl.setValue(selectedUnitSize);
        this.unitSizeChange.emit(selectedUnitSize);
      }
    });
  }

  filterOptions(value: string | UnitSize): UnitSize[] {
    const filterValue: string = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
    return this.unitSizes.filter((option: UnitSize) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  update() {
    this.unitSizeChange.emit(this.unitSizeControl.value);
  }
}
