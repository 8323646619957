import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BeepService {

  private plopAudio = new Audio('assets/audio/plop.wav');
  private completedAudio = new Audio('assets/audio/complete.wav');
  private errorAudio = new Audio('assets/audio/error.wav');

  constructor() {
    this.plopAudio.load();
    this.completedAudio.load();
    this.errorAudio.load();
  }

  plop() {
    this.plopAudio.play();
  }

  completed() {
    this.completedAudio.play();
  }

  error() {
    this.errorAudio.play();
  }

}
