<div *ngIf="item; else scanPaklabel">
  <h2 mat-dialog-title>{{item.deliverAmount}}&nbsp;{{item.articleName}}</h2>
  <mat-dialog-content class="mat-typography" *ngIf="item" [class.checked]="item.allLabelsScanned">
    <div class="row">
      <strong>{{item.location}}</strong>
    </div>
    <div class="row">
      <strong>{{item.remarks}}</strong>
    </div>
    <div class="row">
      <div>Printen:</div>
      <button mat-icon-button color="primary" (click)="openPrintingDialog(item)">
        <mat-icon>printer</mat-icon>
      </button>
    </div>
    <div class="row">
      <div>Commentaar:</div>
      <div id="commentField">{{item.comments}}</div>
      <button mat-icon-button color="primary" (click)="openOpmerkingenDialog(item)">
        <mat-icon *ngIf="!item.comments">note_add</mat-icon>
        <mat-icon *ngIf="item.comments">edit</mat-icon>
      </button>
    </div>
    <div class="row">
      <div>Totaal aantal labels:</div>
      {{item.neededLabels}}
    </div>
    <div class="row">
      <app-counter [count]="item.scannedVml" (countChange)="vmlChanged($event)" [minimum]="0"></app-counter>
      <app-counter [count]="item.scannedVmh" (countChange)="vmhChanged($event)" [minimum]="0"></app-counter>
      <app-counter [count]="item.scannedMerged" (countChange)="mergedBoxesChanged($event)" [minimum]="0"></app-counter>
    </div>
    <div class="button-row">
      <button mat-button color="primary" (click)="checkRestLaag()">Rest Laag</button>
      <button mat-button color="primary" (click)="checkRestHoog()">Rest Hoog</button>
      <button mat-button color="primary" (click)="checkRestSamen()">Rest Samen</button>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="button-row">
      <button *ngIf="item" [disabled]="!previousItem" mat-raised-button color="primary" (click)="goToPrevious()">Vorige
      </button>
      <button mat-raised-button (click)="manuallyChecked()">Handmatig afchecken</button>
    </div>
    <div class="button-col">
      <button mat-raised-button (click)="cancel()"> sluiten </button>
    </div>
  </mat-dialog-actions>
</div>
<div class="loading-shade" *ngIf="savingInProgress$ | async">
  <mat-spinner></mat-spinner>
  <h1>Bezig met opslaan</h1>
  <div>
    <button mat-raised-button color="warn" (click)="cancelSave()">Annuleer</button>
  </div>
</div>
<ng-template #scanPaklabel>
  Scan een paklabel om te beginnen
</ng-template>
