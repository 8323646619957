<h2 mat-dialog-title>Andere bon</h2>
<mat-dialog-content>
    De gescande label hoort bij een andere bon: {{order.id}} <br>
    Naam: {{order.name}} <br>
    Week: {{order.shipmentWeek}} <br><br>
    Wil je deze bon openen?
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="agree()">Ok</button>
    <button mat-raised-button color="basic" (click)="close()">Nee</button>
</mat-dialog-actions>
