import {QuaggaJSConfigObject} from '@ericblade/quagga2';

export const DEFAULT_CONFIG: QuaggaJSConfigObject = {
    inputStream: {
        name: 'Live',
        type: 'LiveStream',
        target: null,
        constraints: {
            width: { min: 640 },
            // aspectRatio: { min: 1, max: 100 },
            facingMode: 'environment', // or user
        },
        singleChannel: false // true: only the red color-channel is read
    },
    locator: {
        patchSize: 'large',
        halfSample: true
    },
    locate: true,
    numOfWorkers: navigator.hardwareConcurrency || 4,
    decoder: {
        readers: ['code_128_reader']
    }
};
