<div class="loading-shade" *ngIf="fetchingBeds$ | async">
  <mat-spinner></mat-spinner>
</div>
<mat-form-field id="search-field" appearance="outline">
  <mat-icon matPrefix>search</mat-icon>
  <mat-label>Bednummer</mat-label>
  <input #bedInput matInput type="search" placeholder="Bednummer" autofocus [(ngModel)]="searchTerm"
         (keyup)="searchTermSubject.next(searchTerm)" (keyup.enter)="searchTermSubject.next(searchTerm)">
  <button mat-button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm=''">
    <mat-icon>&#xE5CD;</mat-icon>
  </button>
</mat-form-field>
<div id="search-result" *ngIf="visibleBeds && visibleBeds.length > 0">
  <div id="search-result-header">
    <div nameCol>Naam</div>
    <div otherCol>Totaal</div>
    <div otherCol>Vrij</div>
    <div otherCol>Status</div>
    <div otherCol>Maat</div>
    <div otherCol>Geteld</div>
  </div>
  <div #scrollContainer id="search-result-beds">
    <button mat-raised-button id="previous-beds-button" class="more-beds-button" [disabled]="firstVisibleBedIndex <= 1" (click)="loadMoreItemsTop()">
      <mat-icon aria-label="Load more">expand_less</mat-icon>
      vorige bedden
    </button>
    <mat-card class="bed" *ngFor="let bed of visibleBeds">
      <mat-card-title class="description">
        <span>{{bed.naam}}</span>
        <button mat-icon-button aria-label="Button to set today as last day seen"
                (click)="setLastInspectionForLocation($event, bed)">
          <mat-icon>preview</mat-icon>
        </button>
      </mat-card-title>
      <mat-card-content>
        <div class="loading-shade" *ngIf="bed.loading">
          <mat-spinner></mat-spinner>
        </div>
        <mat-list class="voorraadlijst">
          <mat-list-item class="voorraadpartij" *ngFor="let voorraad of bed.voorraad"
                         routerLink="/voorraad/stek/{{voorraad.id}}"
                         [class.counted]="isToday(voorraad.lastInspection)">
            <div nameCol>
              <div>{{voorraad.plantName}}</div>
            </div>
            <div otherCol>{{voorraad.total}}</div>
            <div otherCol>{{voorraad.amountFitForSale}}</div>
            <div otherCol>{{voorraad.status}}</div>
            <div otherCol>{{voorraad.unitSizeName}}</div>
            <div otherCol >
              <button mat-icon-button aria-label="Button to set today as last day seen"
                      (click)="setLastInspection($event, voorraad, bed)">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
    <button mat-raised-button id="next-beds-button" class="more-beds-button" [disabled]="firstVisibleBedIndex >= allBeds.length - 10" (click)="loadMoreItemsBottom()">
      <mat-icon aria-label="Load more">expand_more</mat-icon>
      volgende bedden
    </button>
  </div>
</div>
<div *ngIf="noBedsFoundMessage">{{noBedsFoundMessage}}</div>
<button mat-fab id="add-pot-stock-button" class="add-pot-stock-button" color="primary" (click)="openAddStockDialog()">
  <mat-icon aria-label="Add">add</mat-icon>
</button>
