<div class="inputs-row">%<mat-slider min="0" max="100" step="1" thumbLabel="true" [value]="percentage" (input)="onSliderChange($event)" [(value)]="percentage"></mat-slider></div>
<div class="inputs-row"><mat-slide-toggle #toggle color="primary" (change)="onToggleChange($event)" labelPosition="before">-3000</mat-slide-toggle></div>
<mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">
    <i class="material-icons" aria-label="Wijzigingen in vrij annuleren">backspace</i>
    Annuleren
  </button>
  <button mat-raised-button color="primary" (click)="submit()">
    {{potStock.amountFitForSale}}</button>
</mat-dialog-actions>
