<div class="loading-shade" *ngIf="isLoading$ | async">
  <mat-spinner></mat-spinner>
</div>

<div class="buttons" *ngIf="item">
  <button mat-button (click)="cancel()">
    <i class="material-icons" aria-label="Wijzigingen in voorraad annuleren">backspace</i>
    Annuleren
  </button>
  <button mat-button color="primary" (click)="saveAndBack()" *ngIf="!(isLoading$ | async); else disabledSavingBtn;">
    <i class="material-icons" aria-label="Opslaan van voorraad en terug naar vorige">save</i>
    Opslaan
  </button>
  <button mat-button color="primary" (click)="applyChanges()" *ngIf="!(isLoading$ | async); else disabledSavingBtn;">
    <i class="material-icons" aria-label="Opslaan van voorraad">save_alt</i>
    Toepassen
  </button>
  <ng-template #disabledSavingBtn>
    <button mat-button disabled>
      <mat-spinner [diameter]="25" aria-label="Bezig met opslaan..."></mat-spinner>
    </button>
  </ng-template>
</div>

<div id="item" *ngIf="item">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        {{item.plantName}}
      </mat-card-title>
      <mat-card-subtitle>
        {{item.plantCode}} - {{item.unitSizeName}}
        <button mat-icon-button aria-label="Zoek afbeeldingen van deze plant" color="primary"
                (click)="searchForImages()">
          <mat-icon class="material-icons">image_search</mat-icon>
        </button>
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <div class="input-row">
        <mat-form-field>
          <input matInput type="text" placeholder="Bed" [(ngModel)]="item.location"/>
          <button mat-button *ngIf="item.location" matSuffix mat-icon-button aria-label="Clear"
                  (click)="item.location=''">
            <mat-icon>&#xE5CD;</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field>
          <input matInput [matDatepicker]="picker1" placeholder="Datum" [(ngModel)]="item.productionDate">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker1></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="input-row">
        <mat-form-field>
          <input matInput disabled type="text" placeholder="Rest" [(ngModel)]="item.rest"/>
        </mat-form-field>

        <mat-form-field>
          <input matInput disabled type="text" placeholder="Totaal" [(ngModel)]="item.total"/>
        </mat-form-field>

      </div>

      <div class="input-row">
        <mat-form-field>
          <input matInput disabled type="text" placeholder="Gereserveerd" [(ngModel)]="item.amountReserved"/>
        </mat-form-field>

        <div>
          <mat-checkbox matInput color="primary" [(ngModel)]="item.readyForPotting" [labelPosition]="after">
            Oppotbaar
          </mat-checkbox>
        </div>
      </div>

      <div class="input-row">
        <mat-form-field>
          <input matInput disabled type="text" placeholder="Boskoop" [value]="item.boskoopPrice | currency:'EUR'"/>
        </mat-form-field>

        <mat-form-field class="vr">
          <input matInput type="text" placeholder="Vr" [(ngModel)]="item.status"/>
        </mat-form-field>
      </div>

      <div class="input-row">
        <mat-form-field>
          <input matInput type="number" placeholder="Start-aantal" [(ngModel)]="item.originalAmount"
                 (change)="calculateRestAndTotal(item)"/>
          <button mat-button *ngIf="item.amountFitForSale" matSuffix mat-icon-button aria-label="Clear"
                  (click)="item.originalAmount=0">
            <mat-icon>&#xE5CD;</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field class="vrij">
          <input matInput type="number" placeholder="Vrij" [(ngModel)]="item.amountFitForSale"/>
          <button mat-button *ngIf="item.amountFitForSale" matSuffix mat-icon-button aria-label="Clear"
                  (click)="item.amountFitForSale=0">
            <mat-icon>&#xE5CD;</mat-icon>
          </button>
          <button mat-raised-button matSuffix mat-icon-button aria-label="Meer opties" (click)="openVrijOptions()"
                  color="accent">
            %
          </button>
        </mat-form-field>


      </div>

      <div class="input-row">
        <mat-form-field>
          <input matInput type="number" placeholder="Weggegooid" [(ngModel)]="item.amountTrashed"
                 (change)="calculateRestAndTotal()"/>
          <button mat-button *ngIf="item.amountTrashed" matSuffix mat-icon-button aria-label="Clear"
                  (click)="item.amountTrashed=0">
            <mat-icon>&#xE5CD;</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="number" placeholder="Dood" [(ngModel)]="item.dead" (change)="calculateRestAndTotal()"/>
          <button mat-button *ngIf="item.dead" matSuffix mat-icon-button aria-label="Input verwijderen"
                  (click)="item.dead=0">
            <mat-icon>&#xE5CD;</mat-icon>
          </button>
          <button mat-raised-button matSuffix color="warn" mat-icon-button aria-label="Boek dood"
                  (click)="openDeleteDialog()">
            <mat-icon>&#xE92B;</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="input-row">
        <mat-form-field>
          <input matInput type="number" placeholder="Verkoopbaar" [(ngModel)]="item.salable"/>
          <button mat-button *ngIf="item.salable" matSuffix mat-icon-button aria-label="Clear"
                  (click)="item.salable=0">
            <mat-icon>&#xE5CD;</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="number" placeholder="Aantal verpot" [(ngModel)]="item.pottedAmount"
                 (change)="calculateRestAndTotal()"/>
        </mat-form-field>
      </div>

      <div class="input-row">
        <mat-form-field>
          <input matInput type="number" placeholder="Verkocht" [(ngModel)]="item.sold"
                 (change)="calculateRestAndTotal()"/>
          <button mat-button *ngIf="item.sold" matSuffix mat-icon-button aria-label="Clear"
                  (click)="item.sold=0">
            <mat-icon>&#xE5CD;</mat-icon>
          </button>
        </mat-form-field>

        <app-unitsize-autocomplete-field [preFilledUnitSizeId]="item.unitSizeId"
                                         (unitSizeChange)="setUnitSize($event)">Potmaat</app-unitsize-autocomplete-field>
      </div>

      <mat-form-field id="opmerkingen">
        <textarea matInput type="text" placeholder="Opmerkingen" [(ngModel)]="item.remark"></textarea>
        <button mat-button *ngIf="item.remark" matSuffix mat-icon-button aria-label="Input verwijderen"
                (click)="item.remark=''">
          <mat-icon>&#xE5CD;</mat-icon>
        </button>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <app-reserved-stock-overview [stock]="item"></app-reserved-stock-overview>
  <app-search-more code="{{item.plantCode}}"></app-search-more>
</div>

<div *ngIf="error">
  <p>
    Kon partij niet ophalen door een error.
  </p>
  <p>{{error]]</p>
</div>
