<h1 mat-dialog-title>De gehele bon is afgechecked!</h1>
<div mat-dialog-content>
  <h3>{{order.name}}</h3>
  <table>
    <tr>
      <th>VML</th>
      <td>{{totalvml}}</td>
    </tr>
    <tr>
      <th>VMH</th>
      <td>{{totalvmh}}</td>
    </tr>
    <tr>
      <th>Samen</th>
      <td>{{totalMerged}}</td>
    </tr>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-raised-button aria-label="Cancel" (click)="close()">
    Sluit
  </button>
  <button mat-raised-button color="primary" aria-label="Scan" (click)="backToOrderOverview()">
    Naar bonnen
  </button>
</div>


