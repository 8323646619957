import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.scss']
})
export class WarningMessageComponent {
  @Input() shouldDisplay: boolean;
  @Input() title: string;
  @Input() message: string;
}
