import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OpenGroundStock} from '../openGroundStock';

@Component({
  selector: 'app-open-ground-stock-grub-dialog',
  templateUrl: './open-ground-stock-grub-dialog.component.html',
  styleUrls: ['./open-ground-stock-grub-dialog.component.scss']
})
export class OpenGroundStockGrubDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OpenGroundStock,
    public dialogRef: MatDialogRef<OpenGroundStockGrubDialogComponent, Boolean>
  ) { }

  ngOnInit() {
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

}
