import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {PlugStock} from '../plugStock';
import {PlugStockService} from '../plug-stock.service';

@Component({
  selector: 'app-change-all-bed-nr-dialog',
  templateUrl: './change-all-bed-nr-for-plug-stock-dialog.component.html',
  styleUrls: ['./change-all-bed-nr-for-plug-stock-dialog.scss']
})
export class ChangeAllBedNrForPlugStockDialog implements OnInit {

  plantName: string;
  amount: number;
  fromBednr: string;
  toBednr: string;
  errorMessage: string;
  progressBarValue = 0;
  amountSaved = 0;
  toMove: PlugStock[];
  failed: PlugStock[];

  savingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  saving$: Observable<boolean> = this.savingSubject.asObservable();

  constructor(
    public dialogRef: MatDialogRef<ChangeAllBedNrForPlugStockDialog, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: [PlugStock[], string],
    private voorraadService: PlugStockService
  ) {
    this.amount = data[0].length;
    this.toBednr = data[1];
    this.toMove = [...data[0]];
    this.plantName = data[0][0].plantName;
    this.fromBednr = data[0][0].location;
  }

  ngOnInit() {
  }

  async answerYes() {
    this.errorMessage = undefined;
    this.savingSubject.next(true);

    await this.changeAll(this.toMove);

    if (this.failed.length === 0) {
      this.dialogRef.close(true);
    } else {
      this.errorMessage = `Kon ${this.failed.length} regels niet opslaan door een serverfout`;
      this.toMove = this.failed;
    }

    this.savingSubject.next(false);
  }

  private changeAll(stock: PlugStock[]): Promise<any[]> {
    this.failed = [];
    return Promise.all(stock.map(v => {
      v.location = this.data[1];
      console.log(v);
      return this.voorraadService.update(v).toPromise()
        .catch(_ => this.failed.push(v))
        .then(_ => this.progressBarValue = ++this.amountSaved / this.amount * 100);
    }));
  }

  answerNo() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
