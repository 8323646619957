import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SalesOrder} from '../sales-order';
import {SalesOrderService} from '../sales-order.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {LocationService} from '../../_services/location.service';
import {WithScrollContainer} from '../../_helpers/with-scroll-container';
import {MatDialog} from '@angular/material/dialog';
import {ScanBonDialogComponent} from './scan-bon-dialog/scan-bon-dialog.component';
import {faBarcode} from '@fortawesome/free-solid-svg-icons';
import {BarcodeScannerDialogComponent} from '../order-detail/barcode-scanner-dialog/barcode-scanner-dialog.component';
import {Router} from '@angular/router';
import {SelectedLocation} from '../../common/location-selector/locations';


@Component({
  selector: 'app-customer-overview',
  templateUrl: './customer-overview.component.html',
  styleUrls: ['./customer-overview.component.scss']
})
export class CustomerOverviewComponent extends WithScrollContainer() implements OnInit, AfterViewInit, OnDestroy {

  orders: SalesOrder[] = [];
  selectedDay = 'PAK';
  orderDataSource = new MatTableDataSource<TableChecklistOrder>();
  displayedColumns: string[] = ['deliverNr', 'name', 'amountOfLabelsNeeded', 'aantal', 'waar', 'wk', 'shipmentDay', 'pr', 'palletnumber'];
  loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  syncing = false;
  hideChecked = false;
  subscriptions = new Subscription();

  faBarcode = faBarcode;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private salesOrderService: SalesOrderService,
    private snackBar: MatSnackBar,
    private readonly _dialog: MatDialog,
    private readonly locationService: LocationService,
    private readonly _router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.orderDataSource.sortingDataAccessor = (order, property) => {
      switch (property) {
        case 'wk':
          return order.shipmentWeek ? order.shipmentWeek.toLocaleLowerCase() : '';
        case 'name':
          return order.name ? order.name.toLocaleLowerCase() : '';
        case 'waar':
          return order.dropOffLocation ? order.dropOffLocation.toLocaleLowerCase() : '';
        case 'aantal':
          return order.items ? order.items.length : 0;
        default:
          return order[property];
      }
    };
    this.subscriptions.add(this.locationService.observeSelectedLocation().subscribe(() => this.refreshTable()));
    this.getCheckListOrders();
  }

  ngAfterViewInit() {
    this.orderDataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public openCameraScanDialog(): void {
    this._dialog.open(ScanBonDialogComponent);
  }

  public openBarcodeScanDialog(): void {
    const ref = this._dialog.open(BarcodeScannerDialogComponent, {data: {order: undefined, item: undefined}});
    this.subscriptions.add(ref.afterClosed().subscribe(result => {
      if (result) {
        this._router.navigate(['afchecklijst', result.orderId], {queryParams: {orderItemId: result.itemSleutel, device: 'scanner'}});
      } else {
        this.refreshTable();
      }
    }));
  }

  public async onAttach() {
    await this.getCheckListOrders();
    this.hideChecked = this.salesOrderService.getHideChecked();
    this.refreshTable();
    super.onAttach();
  }

  hideCheckChanged() {
    this.salesOrderService.setHideChecked(this.hideChecked);
    this.refreshTable();
  }

  async getCheckListOrders() {
    this.loadingSubject.next(true);
    try {
      this.orders = await this.salesOrderService.getSalesOrdersLowTax().toPromise();
      this.refreshTable();
    } catch (error) {
      console.error('Error while getting checklist orders without items', error);
      this.snackBar.open('Kon afchecklijst niet openen, probeer het later nog eens', 'sluit', {duration: 2000});
    } finally {
      this.loadingSubject.next(false);
    }
  }

  getAmount(order: TableChecklistOrder): string {
    if (order.items) {
      return `${order.amountOfItemsChecked} / ${order.items.length}`;
    } else {
      return '- / -';
    }
  }

  getTotal(): string {
    const stats: number[] = [0, 0];
    this.orderDataSource.data
      .filter(order => order.items && order.items.length > 0)
      .forEach(order => {
        stats[0] += order.amountOfItemsChecked;
        stats[1] += order.items.length;
      });
    return `${stats[0]} / ${stats[1]}`;
  }

  /**
   * Adds the appropiate orders to the table datasource based on business specifications.
   * Note, do not mess with the sequence of the filters and maps since they are interdependant, sadly.
   */
  refreshTable() {
    this.orderDataSource.data = this.orders
      .filter(o => this.isApplicableToSelectedDay(o))
      .map(o => this.createNewOrderWithFilteredItems(o))
      .filter(o => this.locationService.getSelectedLocation() === SelectedLocation.All || (o.items && o.items.length > 0))
      .filter(o => !(this.hideChecked && o.checked));
  }

  // Create new order objects so we dont actually delete orderItems by filtering
  createNewOrderWithFilteredItems(order: SalesOrder): TableChecklistOrder {
    const tableOrder: TableChecklistOrder = {
      ...order, amountOfLabelsNeeded: 0, amountSalesOrderItemsScanned: 0,
      amountOfItemsChecked: 0, isAllPaklabelsPrinted: false, checked: false,
      items: order.items ? order.items.filter(item => this.locationService.isBedNrFromSelectedLocation(item.location)) : []
    };
    tableOrder.items.forEach(item => {
      tableOrder.amountOfItemsChecked += item.allLabelsScanned ? 1 : 0;
      tableOrder.amountOfLabelsNeeded += item.neededLabels ? item.neededLabels : 0;
    });
    tableOrder.checked = this.isChecked(tableOrder);
    return tableOrder;
  }

  isChecked(order: TableChecklistOrder): boolean {
    return order.items &&
      order.items.length > 0 &&
      order.items.length === order.amountOfItemsChecked;
  }

  getTotalNeededLabels() {
    return this.orderDataSource.data.reduce((a, i) => a + i.amountOfLabelsNeeded, 0);
  }

  private isApplicableToSelectedDay(order: SalesOrder): boolean {
    switch (this.selectedDay) {
      case 'ALL':
        return true;
      case 'PAK':
        return order.pr;
    }
  }

}

interface TableChecklistOrder extends SalesOrder {
  checked: boolean;
  isAllPaklabelsPrinted: boolean;
  amountOfItemsChecked: number;
  amountOfLabelsNeeded: number;
}
