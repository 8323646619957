export class CreateStockWithProductionJob {
  originalAmount: number;
  location: string;
  remarks: string;
  productionJobId: number;
  productionDate: Date;
  inputMaterialAmountUsed: number;
  inputMaterialDeadAmount: number;
  bookAutomaticallyFromStock: boolean;

  constructor(originalAmount: number, location: string, remarks: string, productionJobId: number,
              productionDate: Date, inputMaterialAmountUsed: number, inputMaterialDeadAmount: number,
              bookAutomaticallyFromStock: boolean) {
    this.originalAmount = originalAmount;
    this.location = location;
    this.remarks = remarks;
    this.productionJobId = productionJobId;
    this.productionDate = productionDate;
    this.inputMaterialAmountUsed = inputMaterialAmountUsed;
    this.inputMaterialDeadAmount = inputMaterialDeadAmount;
    this.bookAutomaticallyFromStock = bookAutomaticallyFromStock;
  }
}
