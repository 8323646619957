import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

import {QuaggaJSResultObject} from '@ericblade/quagga2';
import {ScannerComponent} from '../../common/scanner/scanner.component';
import {BeepService} from '../../_services/beep.service';


@Component({
  selector: 'app-scan-bon-dialog',
  templateUrl: './scan-barcode-with-camera-dialog.component.html',
  styleUrls: ['./scan-barcode-with-camera-dialog.component.scss']
})
export class ScanBarcodeWithCameraDialog implements AfterViewInit {

  scannerStarted = false;

  @ViewChild(ScannerComponent) scanner: ScannerComponent;

  constructor(
    private dialogRef: MatDialogRef<number>,
    private beepService: BeepService
  ) {
  }

  ngAfterViewInit(): void {
    this.scanner.start();
  }

  onStarted(started: boolean): void {
    this.scannerStarted = started;
  }

  onScanSuccess(result: QuaggaJSResultObject) {
    this.beepService.plop();
    this.scanner.stop();
    const itemSleutel = +result.codeResult.code;
    this.dialogRef.close(itemSleutel);
  }
}
