import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UnitSize} from './unitsize';

@Injectable()
export class UnitsizeService extends BaseService {

  private unitSizeUrl = this.baseUrl + '/unitsize';


  findAllUnitSizes(): Observable<UnitSize[]> {
    return this.http.get<UnitSize[]>(this.unitSizeUrl)
      .pipe(catchError(this.handleError('zoeken van pot maten', <UnitSize[]>[],
        'Fout bij ophalen van alle potmaten')));
  }
}
