  <mat-form-field id="search-field" appearance="outline">
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Zoek op naam, code of bednummer</mat-label>
    <input matInput #searchInput type="search" autofocus
    placeholder="Naam of code" [(ngModel)]="searchTerm"
    (keyup)="searchTermSubject.next(searchTerm)"
    (keyup.enter)="searchTermSubject.next(searchTerm)"
    autocomplete="off">
    <button mat-button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm=''">
      <mat-icon>&#xE5CD;</mat-icon>
    </button>
  </mat-form-field>
  <div #scrollContainer mat-elevation-z8 id="scroll-container">
    <div class="loading-shade" *ngIf="loading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <table mat-table #table [dataSource]="dataSource" class="table"
      matSort matSortActive="plantName" matSortDirection="asc"
      [hidden]="dataSource.data.length === 0">
      <!-- Naam Column -->
      <ng-container matColumnDef="plantName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Naam</th>
        <td mat-cell *matCellDef="let row">{{ row.plantName }}</td>
      </ng-container>
      <!-- Bednr Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Locatie</th>
        <td mat-cell *matCellDef="let row">{{ row.location }} <b>{{ row.size }}</b></td>
      </ng-container>
      <!-- Rest Column -->
      <ng-container matColumnDef="rest">
        <th mat-header-cell *matHeaderCellDef>Totaal</th>
        <td mat-cell *matCellDef="let row">{{ row.total }}</td>
      </ng-container>
      <!-- vrij Column -->
      <ng-container matColumnDef="amountFitForSale">
        <th mat-header-cell *matHeaderCellDef>Vrij</th>
        <td mat-cell *matCellDef="let row">{{ row.amountFitForSale }}</td>
      </ng-container>
      <!-- Vr Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row">{{ row.status }}</td>
      </ng-container>
      <!-- Datum Column -->
      <ng-container matColumnDef="productionDate">
        <th mat-header-cell *matHeaderCellDef>Datum</th>
        <td mat-cell *matCellDef="let row">{{ row.productionDate | date:"dd-MM-yyyy" }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" routerLink="/voorraad/potten/{{row.id}}"></tr>
    </table>
    <div class="no-result-label" *ngIf="noResultsFound">Geen resultaten</div>
  </div>
