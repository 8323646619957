<div *ngIf="vertical; else horizontal">
  <div class="vertical-counter">
    <button mat-icon-button color="primary" (click)="increase()" [disabled]="count >= maximum">
      <mat-icon>add</mat-icon>
    </button>
    {{count}}
    <button mat-icon-button color="primary" (click)="decrease()" [disabled]="count <= minimum">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
</div>

<ng-template #horizontal>
  <span class="horizontal-counter">
    <button mat-icon-button color="primary" (click)="decrease()" [disabled]="count <= minimum">
      <mat-icon>remove</mat-icon>
    </button>
    {{count}}
    <button mat-icon-button color="primary" (click)="increase()" [disabled]="count >= maximum">
      <mat-icon>add</mat-icon>
    </button>
  </span>
</ng-template>
