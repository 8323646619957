<mat-card>
  <mat-card-header>
    <mat-card-title>Reserveringen</mat-card-title>
  </mat-card-header>
  <mat-card-content class="reservation-container">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon *ngIf="stock.hasReservations" color="warn">lock</mat-icon>
          <mat-icon *ngIf="!stock.hasReservations">lock_open</mat-icon>
        </div>
        <mat-card-title>Verkoop</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngIf="!stock.hasReservations; else salesReservation" class="valign-center">
          <span>Geen reservering</span>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon *ngIf="stock.hasProductionReservations" color="warn">lock</mat-icon>
          <mat-icon *ngIf="!stock.hasProductionReservations">lock_open</mat-icon>
        </div>
        <mat-card-title>Productie</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngIf="!stock.hasProductionReservations; else productionReservation" class="valign-center">
          <span>Geen reservering</span>
        </div>
      </mat-card-content>
    </mat-card>

  </mat-card-content>

  <ng-template #salesReservation>
    <div *ngIf="salesReservations$ | async; let reservedStocks; else loadingReservations">
      <mat-list>
        <mat-list-item class="reservation" *ngFor="let reservedStock of reservedStocks; let i = index">
          <h3 matLine>
            {{reservedStock.customerNameInternal}}
          </h3>
          <h4 matLine>
            {{reservedStock.amount}} gereserveerd
          </h4>
          <h4 matLine>
            {{reservedStock.reservationItemDate | date:"dd-MM-yyyy" }}
          </h4>
          <button mat-button matSuffix color="warn" aria-label="Reservering vrijgeven"
                  (click)="handleReleaseSalesReservationButton(reservedStock.id)">
            <mat-icon>lock_open</mat-icon>
            Vrijgeven
          </button>
        </mat-list-item>
      </mat-list>
    </div>
  </ng-template>

  <ng-template #productionReservation>
    <div *ngIf="productionReservations$ | async; let productionStocks; else loadingReservations" class="reservation-container">
      <mat-list>
        <mat-list-item class="reservation" *ngFor="let productionStock of productionStocks; let i = index">
          <h4 matLine>
            {{productionStock.amount}} gereserveerd
          </h4>
          <button mat-button matSuffix color="warn" aria-label="Reservering vrijgeven"
                  (click)="releaseProductionReservation(productionStock.id)">
            <mat-icon>lock_open</mat-icon>
            Vrijgeven
          </button>
        </mat-list-item>
      </mat-list>
    </div>
  </ng-template>

  <ng-template #loadingReservations>
    <mat-spinner [diameter]="25" aria-label="Bezig met laden..."></mat-spinner>
  </ng-template>
</mat-card>
