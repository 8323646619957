import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import {Injectable} from '@angular/core';

/**
 * RouteReuseStrategy that enables caching tellen page
 *
 * Note: will not YET save scroll position.
 * Refer to https://github.com/angular/angular/pull/20030
 * Though this is currently handled in custom logic
 */

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {

    routesToCache: RegExp[] = [
        // This regex will match all current paths except those with a numerical id or sleutel postfixed to prevent caching detail pages
      'voorraad\/?[a-zA-Z\/_-]*\/?$',
      'afchecklijst\/?[a-zA-Z\/_-]*\/?$'
    ].map(str => new RegExp(str));

    storedRouteHandles = new Map<string, DetachedRouteHandle>();

    // Decides if the route should be stored
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return this.routesToCache.some(rx => rx.test(route.routeConfig.path));
    }

    // Store the information for the route we're destructing
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        this.storedRouteHandles.set(route.routeConfig.path, handle);
    }

    // Return true if we have a stored route object for the next route
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return this.storedRouteHandles.has(route.routeConfig.path);
    }

    // If we returned true in shouldAttach(), now return the actual route data for restoration
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return this.storedRouteHandles.get(route.routeConfig.path);
    }

    // Reuse the route if we're going to and from the same route
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }
}
