import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {PotStock} from '../pot';
import {PotService} from '../pot.service';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap} from 'rxjs/operators';
import {WithScrollContainer} from '../../../_helpers/with-scroll-container';

@Component({
  selector: 'app-search-pot',
  templateUrl: './pot-history.component.html',
  styleUrls: ['./pot-history.component.scss']
})
export class PotHistoryComponent extends WithScrollContainer() implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

  displayedColumns = ['plantName', 'location', 'rest', 'amountFitForSale', 'status', 'productionDate'];
  dataSource: MatTableDataSource<PotStock> = new MatTableDataSource<PotStock>();
  loadingSubject = new BehaviorSubject<boolean>(false);
  searchTermSubject = new BehaviorSubject<string>('');
  loading$ = this.loadingSubject.asObservable();
  noResultsFound = false;
  searchTerm: string;

  constructor(
    private voorraadService: PotService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.setupSearchFieldBehaviour();
    this.searchTerm = this.route.snapshot.paramMap.get('searchTerm');
    this.searchTermSubject.next(this.searchTerm);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.searchTermSubject.unsubscribe();
    this.loadingSubject.unsubscribe();
  }

  public async onAttach() {
    if (this.searchTerm) {
      this.findVoorraad(this.searchTerm)
        .subscribe(voorraad => {
          this.dataSource.data = voorraad;
          super.onAttach();
        });
    } else {
      this.searchInput.nativeElement.focus();
    }
  }

  findVoorraad(searchTerm: string): Observable<PotStock[]> {
    if (!searchTerm) {
      return of([]);
    }
    this.loadingSubject.next(true);
    return this.voorraadService.findHistoricStockByCodeOrNameOrLocation(searchTerm).pipe(
      catchError(() => of([])),
      tap(result => this.noResultsFound = result.length === 0),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  private setupSearchFieldBehaviour() {
    this.searchTermSubject.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      filter(str => str && str.length >= 2),
      switchMap(search => this.findVoorraad(search)),
    ).subscribe(voorraad => this.dataSource.data = voorraad);
  }
}
