import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SalesOrderItem} from '../../sales-order-item';
import {BehaviorSubject} from 'rxjs';
import {BarcodeService} from '../../../barcode-scanner/barcode.service';

@Component({
  selector: 'app-scan-print-item',
  templateUrl: './scan-print-item.component.html',
  styleUrls: ['./scan-print-item.component.scss']
})
export class ScanPrintItemComponent implements OnInit {

  @Input() index: number;
  @Input() barcode: number;
  @Output() remove = new EventEmitter<undefined>();

  salesOrderItem: SalesOrderItem;
  isLoadingItem = new BehaviorSubject(true);
  isLoadingItem$ = this.isLoadingItem.asObservable();
  errorMessage: string;

  constructor(private barcodeService: BarcodeService) {
  }

  async ngOnInit() {
    return this.loadItem(this.barcode);
  }

  async loadItem(barcode: number) {
    try {
      this.salesOrderItem = await this.barcodeService.getSalesOrderItemByBarcode(barcode).toPromise();
    } catch (error) {
      this.errorMessage = 'Fout bij ophalen van ' + barcode;
    } finally {
      this.isLoadingItem.next(false);
    }
  }

  removeItem(): void {
    this.remove.emit();
  }
}
