import {IterableDiffer, IterableDiffers, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'reverse',
  pure: false
})
export class ReversePipe implements PipeTransform {

  private differ: IterableDiffer<unknown>;
  private cached: any;

  constructor(differs: IterableDiffers) {
    this.differ = differs.find([]).create(null);
  }

  transform(value) {
    const changes = this.differ.diff(value);
    if (changes) {
      this.cached = value.slice().reverse();
    }
    return this.cached;
  }
}
