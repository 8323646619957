<mat-sidenav-container fullscreen>
  <mat-sidenav class="sidenav" #sidenav fixedInViewport="true" role="navigation" mode="over">
    <mat-toolbar color="primary">
      <span>Menu</span>
      <button class="" mat-icon-button (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>

    </mat-toolbar>
    <mat-nav-list *ngIf="userLoggedIn()">
      <app-menu-list-item *ngFor="let item of navItems" [item]="item"></app-menu-list-item>
      <a mat-list-item class="logOutButton" (click)="logout()">
        <mat-icon class="logOutIcon">logout</mat-icon>
        Uitloggen
      </a>
      <a mat-list-item class="personLoggedIn">
        <mat-icon class="personLoggedInIcon">person</mat-icon>
        Ingelogd: {{ getCurrentUsername() }}
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <header role="banner">
      <mat-toolbar id="main-toolbar" color="primary">
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="sidenav.toggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span>{{ title }}</span>
        <span class="spacer"></span>
        <app-pwa-controls></app-pwa-controls>
      </mat-toolbar>
    </header>
    <main main-content>
      <!-- <div main-content> -->
      <ng-content></ng-content>
      <!-- </div> -->
    </main>
    <footer>
      <!-- TODO some footer -->
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
