import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ToastrService} from 'ngx-toastr';
import {PlugStockService} from '../plug-stock.service';
import {PlantService} from '../../../plant/plant.service.tx';
import {PlugStock} from '../plugStock';
import {Plant} from '../../../plant/plant';


@Component({
  selector: 'app-add-new-stock-dialog',
  templateUrl: './add-new-plug-stock-dialog.component.html',
  styleUrls: ['./add-new-plug-stock-dialog.component.scss']
})
export class AddNewPlugStockDialogComponent {

  plantCode: string;
  bedNr: string;
  amountOfPots: number;
  amountFitForSale: number;
  unitSizeName = 'STK';
  isPlantCodeValid = false;

  constructor(
    public dialogRef: MatDialogRef<AddNewPlugStockDialogComponent>,
    private voorraadService: PlugStockService,
    private snackBar: MatSnackBar,
    private toastr: ToastrService,
    public plantService: PlantService) {
  }

  cancel(): void {
    this.dialogRef.close();
  }

  async onSubmitClick() {
    const item: PlugStock = {
      location: this.bedNr,
      plantCode: this.plantCode,
      originalAmount: this.amountOfPots,
      rest: this.amountOfPots,
      amountFitForSale: this.amountFitForSale,
      dead: 0,
      amountTrashed: 0,
      unitSizeName: this.unitSizeName,
    };
    try {
      await this.voorraadService.createPlugStock(item).toPromise();
      this.toastr.success('Voorraad regel met plantcode ' + item.plantCode + ' opgeslagen!');
      this.dialogRef.close();
    } catch (error) {
      this.toastr.error('Er is iets fout gegaan, heb je de juiste plantcode?');
    }
  }

  isDisabled(): boolean {
    return !this.plantCode || !this.bedNr;
  }

  updatePlantCode(plantCode: string) {
    this.plantCode = plantCode;
    this.plantService.checkIfCodeIsValid(plantCode).subscribe(
      (response) => {
        this.isPlantCodeValid = response;
      },
      (error) => {
        this.toastr.error('Er is iets fout gegaan, probeer het a.u.b. opnieuw');
        console.error(error);
      }
    );
  }

  updateSelectedPlant($event: Plant) {
    this.updatePlantCode($event.code);
  }
}
