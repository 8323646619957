import {Injectable} from '@angular/core';
import {BaseService} from '../../common/base.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BarcodePrintService extends BaseService {

  private url = this.baseUrl + '/barcodes';

  printBarcodeList(barcodeList: number[]): Observable<string> {
    return this.http.post(this.url + '/print', barcodeList, {responseType: 'text'});
  }
}
