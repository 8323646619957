import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {SalesOrderService} from './sales-order.service';
import {SalesOrder} from './sales-order';
import {Injectable} from '@angular/core';
import {first} from 'rxjs/operators';

@Injectable()
export class CheckListOrderResolver implements Resolve<Observable<SalesOrder>> {

    constructor(readonly salesOrderService: SalesOrderService) { }

    resolve(route: ActivatedRouteSnapshot) {
        const orderId: number = +route.paramMap.get('orderId');
        return this.salesOrderService.getSalesOrderForCheckList(orderId).pipe(first());
    }
}
