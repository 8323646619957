import {PotStock} from './pot';

export class LocationWithVoorraad {
  location: string;
  stocks: PotStock[];
  priority: number;

  constructor(location: string, stocks?: PotStock[], priority?: number) {
    this.location = location;
    this.stocks = stocks;
    this.priority = priority;
  }
}
