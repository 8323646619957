import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {CreateOpenGroundStockDialogComponent} from './create-stock-dialog/create-open-ground-stock-dialog.component';
import {LocationService} from '../../../_services/location.service';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {WithScrollContainer} from '../../../_helpers/with-scroll-container';
import {ProductionJob} from '../../pot/production-job';
import {OpenGroundProductionJobService} from '../open-ground-production-job.service';

@Component({
  selector: 'app-pot-jobs',
  templateUrl: './planting-jobs.component.html',
  styleUrls: ['./planting-jobs.component.scss']
})
export class PlantingJobsComponent extends WithScrollContainer() implements OnInit, OnDestroy {

  private readonly onDestroy$ = new Subject<void>();
  fromDate: string;
  untilDate: string;
  sortKeyword: string;
  searchTermSubject = new BehaviorSubject<string>('');
  plantingJobs: ProductionJob[] = [];
  filteredPlantingJobs: ProductionJob[] = [];
  plantingJobsToShow: ProductionJob[] = [];
  busyFetching = new BehaviorSubject<boolean>(false);
  noJobsFoundMessage: string;
  subscriptions = new Subscription();
  firstVisibleProdutionJobIndex: number;
  private readonly AMOUNT_PRODUCTION_JOBS_VISIBLE = 100;

  constructor(
    private openGroundProductionJobService: OpenGroundProductionJobService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private locationService: LocationService
  ) {
    super();
    this.firstVisibleProdutionJobIndex = 0;
  }

  ngOnInit(): void {
    this.refreshData();
    this.subscriptions.add(this.route.data.subscribe(data => {
      this.openDialogWhenIdProvided(data.productionJob);
    }));
    this.subscriptions.add(this.locationService.observeSelectedLocation().subscribe(() => {
      this.refreshTable();
      this.syncVisiblePottingJobsWithAllPottingJobs();
    }));
    this.setupSearchFieldBehaviour();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async fetchAllProductionJobsForTimePeriod() {
    this.busyFetching.next(true);
    try {
      this.plantingJobs = await this.openGroundProductionJobService.getAllProductionJobsForWeek(this.fromDate, this.untilDate).toPromise();
      this.setupJobsAvailableMessage();
    } catch (error) {
      console.error('Unable to get all production jobs: ' + error);
    }
    this.busyFetching.next(false);
  }

  private refreshTable() {
    this.firstVisibleProdutionJobIndex = 0;
    this.filteredPlantingJobs = this.plantingJobs;
    if (this.sortKeyword !== undefined && this.sortKeyword.length > 0) {
      this.applyFilter(this.sortKeyword);
    }
    this.filteredPlantingJobs = this.filteredPlantingJobs
      .sort((a: ProductionJob, b: ProductionJob) => {
        if (a.priority && !b.priority) {
          return -1;
        } else if (!a.priority && b.priority) {
          return 1;
        } else {
          return a.plantName.localeCompare(b.plantName);
        }
      })
      .filter(item => this.locationService.isBedNrFromSelectedLocation(item.deliveryLocation));
  }

  async fetchAllProductionJobs() {
    this.busyFetching.next(true);
    try {
      this.plantingJobs = await this.openGroundProductionJobService.getAllProductionJobs().toPromise();
      this.setupJobsAvailableMessage();
    } catch (error) {
      console.error('Unable to get all production jobs: ' + error);
    }
    this.busyFetching.next(false);
  }

  setupJobsAvailableMessage() {
    if (this.plantingJobs.length > 0) {
      this.noJobsFoundMessage = undefined;
    } else {
      this.noJobsFoundMessage = 'Geen productie opdrachten gevonden voor de geselecteerde week';
    }
  }

  openCreateStockDialog(potProductionJob: ProductionJob) {
    const dialogRef = this.dialog.open(CreateOpenGroundStockDialogComponent, {
      width: '450px',
      data: potProductionJob
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refreshData();
    });
  }

  refreshData() {
    if (!this.fromDate || !this.untilDate ||
      this.fromDate.length === 0 || this.untilDate.length === 0) {
      this.fetchAllProductionJobs().then(() => {
        this.refreshTable();
        this.syncVisiblePottingJobsWithAllPottingJobs();
      });
    } else {
      this.fetchAllProductionJobsForTimePeriod().then(() => {
        this.refreshTable();
        this.syncVisiblePottingJobsWithAllPottingJobs();
      });
    }
  }

  private setupSearchFieldBehaviour() {
    this.searchTermSubject.pipe(
      takeUntil(this.onDestroy$),
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe(searchTerm => this.applyFilter(searchTerm));
  }

  private applyFilter(term: string) {
    this.plantingJobsToShow = [];
    term = term.trim();
    if (term === undefined || term.length === 0) {
      this.filteredPlantingJobs = this.plantingJobs;
    }
    this.filteredPlantingJobs = this.plantingJobs.filter(item => item.plantName.toLowerCase().includes(term.toLowerCase())
      || item.plantCode.toLowerCase().includes(term.toLowerCase()))
      .filter(item => this.locationService.isBedNrFromSelectedLocation(item.deliveryLocation));
    if (this.filteredPlantingJobs.length >= 0) {
      this.noJobsFoundMessage = undefined;
      this.syncVisiblePottingJobsWithAllPottingJobs();
    } else {
      this.noJobsFoundMessage = 'Geen productie opdracht gevonden voor zoekterm: ' + term;
    }
  }

  syncVisiblePottingJobsWithAllPottingJobs() {
    this.plantingJobsToShow = this.filteredPlantingJobs.slice(this.firstVisibleProdutionJobIndex,
      this.firstVisibleProdutionJobIndex + this.AMOUNT_PRODUCTION_JOBS_VISIBLE);
  }

  loadMoreItemsBottom() {
    const productionJobsToAdd: ProductionJob[] = this.filteredPlantingJobs
      .slice(this.firstVisibleProdutionJobIndex + this.AMOUNT_PRODUCTION_JOBS_VISIBLE,
        this.firstVisibleProdutionJobIndex + this.AMOUNT_PRODUCTION_JOBS_VISIBLE + 50);
    productionJobsToAdd.forEach(bed => this.plantingJobsToShow.push(bed));
    this.plantingJobsToShow.splice(0, productionJobsToAdd.length);
    this.firstVisibleProdutionJobIndex += productionJobsToAdd.length;
  }

  private openDialogWhenIdProvided(productionJob: ProductionJob) {
    if (productionJob) {
      this.openCreateStockDialog(productionJob);
    }
  }
}
