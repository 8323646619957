import {Component, HostListener, OnInit} from '@angular/core';
import {buffer, filter, map, throttleTime} from 'rxjs/operators';
import {asyncScheduler, Subject, Subscription} from 'rxjs';
import {SalesOrder} from '../checklist/sales-order';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ScanBarcodeWithCameraDialog} from './scan-barcode-with-camera-dialog/scan-barcode-with-camera-dialog.component';
import {BarcodeScannerService} from './barcode-scanner.service';

@Component({
  selector: 'app-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss']
})
export class BarcodeScannerComponent implements OnInit {

  order: SalesOrder;
  subscriptions = new Subscription();
  barcodeInputSubject = new Subject<number>();
  barcodeInput$ = this.barcodeInputSubject.asObservable();


  constructor(
    private barcodeScannerService: BarcodeScannerService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setupBarcodeScanning();
  }

  private setupBarcodeScanning() {
    this.subscriptions.add(this.barcodeInput$.pipe(
      buffer(this.barcodeInput$.pipe(throttleTime(100, asyncScheduler, {leading: false, trailing: true}))),
      filter(barcodeArray => barcodeArray.length > 3),
      map(barcodeArray => +barcodeArray.join(''))
    ).subscribe({
      next: (barcode: number) => {
        this.barcodeScannerService.nextBarcode(barcode);
      }
    }));
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.defaultPrevented) {
      return; // Should do nothing if the default action has been cancelled
    }
    const number = Number(event.key);
    if (number >= 0) {
      this.barcodeInputSubject.next(number);
      event.preventDefault();
    }
  }

  openCameraScanner() {
    const dialog: MatDialogRef<ScanBarcodeWithCameraDialog> = this._dialog.open(ScanBarcodeWithCameraDialog, {});
    dialog.afterClosed().subscribe((barcode: number) => {
      if (barcode) {
        this.barcodeScannerService.nextBarcode(barcode);
      }
    });
  }
}
