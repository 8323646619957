import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, forkJoin, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, finalize, takeUntil} from 'rxjs/operators';
import {PotService} from '../pot.service';
import {PotStock} from '../pot';
import {WithScrollContainer} from '../../../_helpers/with-scroll-container';
import {AddNewStockDialogComponent} from '../add-new-stock-dialog/add-new-stock-dialog.component';
import {MatDialog} from '@angular/material/dialog';

class BedWithVoorraad {
  naam: string;
  voorraad: PotStock[];
  loading = false;

  constructor(naam: string, voorraad?: PotStock[]) {
    this.naam = naam;
    this.voorraad = voorraad;
  }
}

@Component({
  selector: 'app-pot-count',
  templateUrl: './pot-count.component.html',
  styleUrls: ['./pot-count.component.scss']
})
export class PotCountComponent extends WithScrollContainer() implements OnInit, AfterViewInit, OnDestroy {
  private readonly onDestroy$ = new Subject<void>();

  @ViewChild('bedInput', {static: true}) bedInput: ElementRef;

  searchTerm: string;
  searchTermSubject = new BehaviorSubject<string>('');
  allBeds: BedWithVoorraad[] = [];
  visibleBeds: BedWithVoorraad[] = [];
  displayedColumns = ['naam', 'total', 'vrij', 'vr', 'potmaat'];
  fetchingBedsSubject = new BehaviorSubject<boolean>(false);
  fetchingBeds$: Observable<boolean> = this.fetchingBedsSubject.asObservable();
  firstVisibleBedIndex: number;
  noBedsFoundMessage: string;
  private previousDates: Map<number, Date> = new Map<number, Date>();

  private readonly AMOUNT_BEDS_VISIBLE = 10;

  constructor(
    private potService: PotService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.setupSearchFieldBehaviour();
    this.fetchAllBeds().then(() => {
      this.searchTerm = this.route.snapshot.paramMap.get('bednr');
      this.searchTermSubject.next(this.searchTerm);
    });
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async fetchAllBeds() {
    this.fetchingBedsSubject.next(true);
    try {
      const beds = await this.potService.getBedNrs().toPromise();
      this.allBeds = beds.filter(val => val !== null)
        .sort((a, b) => a.localeCompare(b))
        .map(bed => new BedWithVoorraad(bed));
    } catch (error) {
      console.error('Unable to get all beds: ' + error);
    }
    this.fetchingBedsSubject.next(false);
  }

  syncVisibleBedsWithAllBeds() {
    this.visibleBeds = this.allBeds.slice(this.firstVisibleBedIndex, this.firstVisibleBedIndex + this.AMOUNT_BEDS_VISIBLE);
  }

  async fetchVoorraad(bed: BedWithVoorraad) {
    bed.loading = true;
    try {
      const retrievedStock = await this.potService.getPotStockByBedNr(bed.naam).toPromise();
      bed.voorraad = retrievedStock.sort((a, b) => a.plantName.localeCompare(b.plantName));
      bed.loading = false;
    } catch (error) {
      console.error('Unable to get voorraad for bednr ' + bed.naam + '\n' + error);
    }
  }

  isToday(someDate: Date) {
    if (someDate !== undefined) {
      const dateParts = someDate.toString().split('-').map(str => +str);
      const today = new Date();
      return dateParts[2] === today.getDate() &&
        dateParts[1] === today.getMonth() + 1 &&
        dateParts[0] === today.getFullYear();
    }
  }

  loadMoreItemsBottom() {
    const bedsToAdd: BedWithVoorraad[] = this.allBeds.slice(this.firstVisibleBedIndex + this.AMOUNT_BEDS_VISIBLE,
      this.firstVisibleBedIndex + this.AMOUNT_BEDS_VISIBLE + 5);
    bedsToAdd.forEach(bed => this.visibleBeds.push(bed));
    this.visibleBeds.splice(0, bedsToAdd.length);
    this.firstVisibleBedIndex += bedsToAdd.length;
    bedsToAdd.forEach(bed => this.fetchVoorraad(bed));
  }

  loadMoreItemsTop() {
    let startIndex = this.firstVisibleBedIndex - 6;
    const lastIndex = this.firstVisibleBedIndex - 1;
    if (this.firstVisibleBedIndex - 5 < 0) {
      startIndex = 0;
    }
    const bedsToAdd: BedWithVoorraad[] = this.allBeds.slice(startIndex, lastIndex);
    bedsToAdd.reverse().forEach(bed => this.visibleBeds.unshift(bed));
    this.firstVisibleBedIndex -= bedsToAdd.length;
    this.visibleBeds.splice(-(bedsToAdd.length), bedsToAdd.length);
    bedsToAdd.forEach(bed => this.fetchVoorraad(bed));
  }

  public async onAttach() {
    this.fetchAllBeds().then(() => {
      this.syncVisibleBedsWithAllBeds();
      this.fetchingBedsSubject.next(true);
      forkJoin(this.visibleBeds.map(bed => this.fetchVoorraad(bed)))
        .pipe(finalize(() => this.fetchingBedsSubject.next(false)))
        .subscribe(() => {
          if (this.scrollPosition) {
            this.scrollContainer.nativeElement.scrollTo(0, this.scrollPosition);
          } else if (!this.searchTerm) {
            this.bedInput.nativeElement.focus();
          }
        });
    });
  }

  private setupSearchFieldBehaviour() {
    this.searchTermSubject.pipe(
      takeUntil(this.onDestroy$),
      debounceTime(1000),
      filter(str => str && str.length >= 2),
      distinctUntilChanged(),
    ).subscribe(searchTerm => this.applyFilter(searchTerm));
  }

  private applyFilter(term: string) {
    this.visibleBeds = [];
    if (term && term.length >= 2) {
      term = term.trim();
      const index = this.allBeds.findIndex(bed => bed.naam && bed.naam.indexOf(term) !== -1);
      if (index >= 0) {
        this.noBedsFoundMessage = undefined;
        this.firstVisibleBedIndex = index;
        this.syncVisibleBedsWithAllBeds();
        this.visibleBeds.forEach(bed => this.fetchVoorraad(bed));
      } else {
        this.noBedsFoundMessage = 'Geen bedden gevonden voor zoekterm: ' + term;
      }
    }
  }

  openAddStockDialog() {
    this.dialog.open(AddNewStockDialogComponent, {
      width: '600px',
      height: '600px',
      maxWidth: '90%',
      maxHeight: '90%',
    })
      .afterClosed()
      .subscribe(() => this.onAttach());
  }

  async setLastInspection(event: MouseEvent, stock: PotStock, bed: BedWithVoorraad) {
    event.stopPropagation();
    await this.updateStockInspection(stock);
    await this.fetchVoorraad(bed);
  }

  async setLastInspectionForLocation(event: MouseEvent, bed: BedWithVoorraad) {
    event.stopPropagation();
    const updatePromises = bed.voorraad.map((stock: PotStock) => this.updateStockInspection(stock));
    await Promise.all(updatePromises);
    await this.fetchVoorraad(bed);
  }

  private async updateStockInspection(stock: PotStock) {
    if (this.previousDates.has(stock.id) &&
      (new Date(this.previousDates.get(stock.id)) < new Date() || this.previousDates.get(stock.id) === undefined)) {
      stock.lastInspection = this.previousDates.get(stock.id);
      this.previousDates.delete(stock.id);
    } else {
      this.previousDates.set(stock.id, stock.lastInspection);
      stock.lastInspection = new Date();
    }
    await this.potService.updateStock(stock).toPromise();
  }
}
