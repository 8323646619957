import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-counter-text',
  templateUrl: './counter-text.component.html',
  styleUrls: ['./counter-text.component.scss']
})
export class CounterTextComponent implements OnInit {

  private debouncer: Subject<number> = new Subject<number>();
  private readonly debounceTime = 200;

  @Input()
  count = 0;

  @Input()
  vertical = true;

  @Input()
  minimum = Number.MIN_SAFE_INTEGER;

  @Input()
  maximum = Number.MAX_SAFE_INTEGER;

  @Output()
  countChange = new EventEmitter<number>();


  constructor() {
    this.debouncer
      .pipe(debounceTime(this.debounceTime))
      .subscribe(val => this.countChange.emit(val));
  }

  ngOnInit() {
  }

  increase() {
    this.debouncer.next(++this.count);
  }

  decrease() {
    this.debouncer.next(--this.count);
  }

  update() {
    this.debouncer.next(this.count);
  }
}
