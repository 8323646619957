import {Injectable} from '@angular/core';
import {BaseService} from '../../common/base.service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ProductionJob} from './production-job';

@Injectable()
export class ProductionJobService extends BaseService {

  private url = this.baseUrl + '/production-job';

  findActiveProductionJobById(productionJobId: number): Observable<ProductionJob> {
    return this.http.get<ProductionJob>(this.url + '/active-job/' + productionJobId)
      .pipe(catchError(this.handleError('Ophalen van actieve oppot opdracht', null)));
  }
}
