<h1>Voorraad aanmaken</h1>
<h2>{{ data.plantName }}</h2>
<mat-dialog-content class="dialog-content-alignment">
  <mat-form-field *ngIf="data.hasReservedStock">
    <input matInput type="number" placeholder="Gereserveerde Hoeveelheid" [(ngModel)]="data.totalReservedAmount"
           [disabled]="true">
  </mat-form-field>
  <mat-form-field *ngIf="data.hasReservedStock">
    <input matInput type="number" placeholder="Ingangsmateriaal gebruikt" [(ngModel)]="inputMaterialAmount">
  </mat-form-field>
  <mat-form-field *ngIf="data.hasReservedStock">
    <input matInput type="number" placeholder="Dood boeken" [(ngModel)]="inputMaterialDeadAmount">
  </mat-form-field>
  <mat-form-field>
    <input matInput type="number" placeholder="Originele Hoeveelheid" [(ngModel)]="originalAmount">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Bed nummer" [(ngModel)]="location" (ngModelChange)="saveLocationToLocalStorage($event)">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Opmerking" [(ngModel)]="remarks">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Productie datum</mat-label>
    <div class="date-picker-production-date">
      <input matInput [matDatepicker]="picker" [(ngModel)]="productionDate" (ngModelChange)="saveDateToLocalStorage($event)">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </mat-form-field>
  <mat-dialog-actions class="">
    <button mat-raised-button (click)="cancel()">
      <i class="material-icons" aria-label="Annuleren">backspace</i>
      Annuleren
    </button>
    <button [disabled]="isDisabled()" mat-raised-button color="primary" (click)="onSubmitClick()">
      Toevoegen
      <mat-icon>archive</mat-icon>
    </button>
  </mat-dialog-actions>
</mat-dialog-content>




