import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Observable, Subscription} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';
import {NavService} from '../_services/nav.service';
import {NavItem} from './nav-item';
import {Location} from '@angular/common';
import {AuthenticationService} from '../_services/authentication.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('sidenav', {static: true}) sideNav: ElementRef;
  changeTitleSubscription: Subscription;
  title: String;

  navItems: NavItem[] = [
    {
      displayName: 'Portaal',
      iconName: 'home',
      route: '/'
    },
    {
      displayName: 'Zoeken',
      iconName: 'search',
      children: [
        {
          displayName: 'Potten',
          iconName: '',
          route: '/voorraad/potten'
        },
        {
          displayName: 'Vollegrond',
          iconName: '',
          route: '/voorraad/vollegrond'
        },
        {
          displayName: 'Stek',
          iconName: '',
          route: '/voorraad/stek'
        },
      ]
    },
    {
      displayName: 'Tellen',
      iconName: 'visibility',
      route: '/voorraad/potten/tellen'
    },
    {
      displayName: 'Prioriteiten tellen',
      iconName: 'visibility',
      route: '/voorraad/potten/prioriteiten-tellen'
    },
    {
      displayName: 'Afchecklijst',
      iconName: 'check',
      children: [
        {
          displayName: 'Afchecken',
          iconName: 'list',
          route: '/afchecklijst'
        },
        {
          displayName: 'Laatste regels',
          iconName: 'done_all',
          route: '/afchecklijst/regels'
        },
        {
          displayName: 'Totalen',
          iconName: 'save',
          route: '/afchecklijst/totalen'
        }
      ]
    },
    {
      displayName: 'Print',
      iconName: 'print',
      children: [
        {
          displayName: 'Promesse',
          iconName: '',
          route: '/print/promesse'
        }
      ]
    }
  ];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private navService: NavService,
    private _location: Location,
    private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.changeTitleSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data => {
      this.title = data['title'];
      this.titleService.setTitle('Heutinck Portaal - ' + data['title'] || '');
    });
  }

  ngAfterViewInit(): void {
    this.navService.appDrawer = this.sideNav;
  }

  ngOnDestroy() {
    this.changeTitleSubscription.unsubscribe();
  }

  refresh() {
    window.location.reload();
  }

  logout() {
    this.authenticationService.logout();
    window.location.reload();
  }

  getCurrentUsername() {
    return this.authenticationService.getUserName();
  }

  userLoggedIn(): boolean {
    return this.authenticationService.isAuthenticated();
  }
}
