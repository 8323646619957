import {Component, OnDestroy, OnInit} from '@angular/core';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {LocationService} from '../../../_services/location.service';
import {WithScrollContainer} from '../../../_helpers/with-scroll-container';
import {ProductionJob} from '../production-job';
import {PotProductionJobService} from '../potting-production-job.service';
import {ToastrService} from 'ngx-toastr';
import {CreateStockDialogComponent} from '../pot-jobs/create-stock-dialog/create-stock-dialog.component';

@Component({
  selector: 'app-pot-stock-count-and-place',
  templateUrl: './pot-stock-count-and-place.component.html',
  styleUrls: ['./pot-stock-count-and-place.component.scss']
})
export class PotStockCountAndPlaceComponent extends WithScrollContainer() implements OnInit, OnDestroy {

  private readonly onDestroy$ = new Subject<void>();
  sortKeyword: string;
  searchTermSubject = new BehaviorSubject<string>('');
  pottingProductionJobs: ProductionJob[] = [];
  filteredPottingProductionJobs: ProductionJob[] = [];
  pottingProductionJobsToShow: ProductionJob[] = [];
  busyFetching = new BehaviorSubject<boolean>(false);
  noProductionJobsFoundMessage: string;
  subscriptions = new Subscription();
  firstVisiblePottingProductionJobsIndex: number;
  private readonly AMOUNT_POT_PRODUCTION_JOBS_VISIBLE = 100;

  constructor(
    private potProductionJobService: PotProductionJobService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private locationService: LocationService,
    private toastr: ToastrService
  ) {
    super();
    this.firstVisiblePottingProductionJobsIndex = 0;
  }

  ngOnInit(): void {
    this.refreshData();
    this.subscriptions.add(this.locationService.observeSelectedLocation().subscribe(() => {
      this.refreshTable();
      this.syncVisiblePottingJobsWithAllPottingJobs();
    }));
    this.setupSearchFieldBehaviour();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private refreshTable() {
    this.firstVisiblePottingProductionJobsIndex = 0;
    this.filteredPottingProductionJobs = this.pottingProductionJobs;
    if (this.sortKeyword !== undefined && this.sortKeyword.length > 0) {
      this.applyFilter(this.sortKeyword);
    }
    this.filteredPottingProductionJobs = this.filteredPottingProductionJobs
      .sort((a: ProductionJob, b: ProductionJob) => a.plantName.localeCompare(b.plantName))
      .filter(item => this.locationService.isProductionLocationFromSelectedLocation(item.deliveryLocation));
  }

  async fetchAllProductionJobsNeedingToBePlaced() {
    this.busyFetching.next(true);
    await this.potProductionJobService.fetchProductionJobsMarkedProduced()
      .toPromise()
      .then(productionJobs => {
        this.pottingProductionJobs = productionJobs;
        this.toastr.success('Productie opdrachten om weg te zetten opgehaald.');
      })
      .catch(err => {
        this.toastr.error('Er is iets misgegaan bij het ophalen van productie opdrachten');
        console.error(err);
      })
      .finally(() => {
        this.busyFetching.next(false);
        this.setupJobsAvailableMessage();
      });
  }

  setupJobsAvailableMessage() {
    if (this.pottingProductionJobs.length > 0) {
      this.noProductionJobsFoundMessage = undefined;
    } else {
      this.noProductionJobsFoundMessage = 'Geen geproduceerde partijen gevonden die weggezet moeten worden';
    }
  }

  openUpdateStockDialog(productionJob: ProductionJob) {
    const dialogRef = this.dialog.open(CreateStockDialogComponent, {
      width: '90%',
      data: {
          productionJob: productionJob,
          allowMarkingAsProduced: false
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refreshData();
    });
  }

  refreshData() {
    this.fetchAllProductionJobsNeedingToBePlaced().then(() => {
      this.refreshTable();
      this.syncVisiblePottingJobsWithAllPottingJobs();
    });
  }

  private setupSearchFieldBehaviour() {
    this.searchTermSubject.pipe(
      takeUntil(this.onDestroy$),
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe(searchTerm => this.applyFilter(searchTerm));
  }

  private applyFilter(term: string) {
    this.pottingProductionJobsToShow = [];
    term = term.trim();
    if (term === undefined || term.length === 0) {
      this.filteredPottingProductionJobs = this.pottingProductionJobs;
    }
    this.filteredPottingProductionJobs = this.pottingProductionJobs
      .filter(item => item.plantName.toLowerCase().includes(term.toLowerCase())
        || item.plantCode.toLowerCase().includes(term.toLowerCase()))
      .filter(item => this.locationService.isProductionLocationFromSelectedLocation(item.deliveryLocation));
    if (this.filteredPottingProductionJobs.length > 0) {
      this.noProductionJobsFoundMessage = undefined;
      this.syncVisiblePottingJobsWithAllPottingJobs();
    } else {
      this.noProductionJobsFoundMessage = 'Geen productie opdracht gevonden voor zoekterm: ' + term;
    }
  }

  syncVisiblePottingJobsWithAllPottingJobs() {
    this.pottingProductionJobsToShow = this.filteredPottingProductionJobs.slice(this.firstVisiblePottingProductionJobsIndex,
      this.firstVisiblePottingProductionJobsIndex + this.AMOUNT_POT_PRODUCTION_JOBS_VISIBLE);
  }

  loadMoreItemsBottom() {
    const productionJobsToAdd: ProductionJob[] = this.filteredPottingProductionJobs
      .slice(this.firstVisiblePottingProductionJobsIndex + this.AMOUNT_POT_PRODUCTION_JOBS_VISIBLE,
        this.firstVisiblePottingProductionJobsIndex + this.AMOUNT_POT_PRODUCTION_JOBS_VISIBLE + 50);
    productionJobsToAdd.forEach(bed => this.pottingProductionJobsToShow.push(bed));
    this.pottingProductionJobsToShow.splice(0, productionJobsToAdd.length);
    this.firstVisiblePottingProductionJobsIndex += productionJobsToAdd.length;
  }
}

