import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';

@Component({
  selector: 'app-auto-complete-text-field',
  templateUrl: './auto-complete-text-field.component.html',
  styleUrls: ['./auto-complete-text-field.component.scss']
})
export class AutoCompleteTextFieldComponent implements OnInit {
  entityControl = new FormControl();
  entities: Observable<any[]>;

  @Input() fetchEntities: (input: string) => Observable<any[]>;
  @Input() preFilledEntityId: number;
  @Input() placeholder: string;
  @Output() entityChange = new EventEmitter<any>();

  ngOnInit() {
    this.entityControl.valueChanges.pipe(
      debounceTime(300),
      filter((value) => value.length > 2)
    ).subscribe((value) => {
      this.fetchOptions(value);
    });
  }

  fetchOptions(input: string) {
    if (input && this.fetchEntities) {
      this.entities = this.fetchEntities(input);
      this.entities.subscribe((response: any[]) => {
        if (this.preFilledEntityId) {
          const selectedEntity = response.find(entity => entity.id === this.preFilledEntityId);
          this.entityControl.setValue(selectedEntity);
          this.entityChange.emit(selectedEntity);
        }
      }, (error) => {
        console.error(error);
      });
    }
  }

  update() {
    this.entityChange.emit(this.entityControl.value);
  }
}
