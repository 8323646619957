import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ReservedProductionStock, ReservedSalesStock} from '../../../reservation/reservedStock';
import {share} from 'rxjs/operators';
import {ReservationService} from '../../../reservation/reservation.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Stock} from '../../stock';
import {ReleaseAfroepDialogComponent} from '../../pot/pot-detail/release-afroep-dialog/release-afroep-dialog.component';

@Component({
  selector: 'app-reserved-stock-overview',
  templateUrl: './reserved-stock-overview.component.html',
  styleUrls: ['./reserved-stock-overview.component.scss']
})
export class ReservedStockOverviewComponent implements OnInit {

  private _stock!: Stock;
  @Input() set stock(value: Stock) {
    this._stock = value;
    this.fetchReservationsForStockId(value.id);
  }

  get stock() {
    return this._stock;
  }
  loadingSubject = new BehaviorSubject<boolean>(true);
  salesReservations$: Observable<ReservedSalesStock[]>;
  productionReservations$: Observable<ReservedProductionStock[]>;

  constructor(
    private reservationService: ReservationService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
  }

  fetchReservationsForStockId(id: number) {
    if (this.stock.hasReservations) {
      this.salesReservations$ =
        this.reservationService.getReservedSalesStockByStockId(this.stock.id)
          .pipe(share());
    }
    if (this.stock.hasProductionReservations) {
      this.productionReservations$ =
        this.reservationService.getReservedProductionStockByStockId(this.stock.id)
          .pipe(share());
    }
  }

  async handleReleaseSalesReservationButton(id: number) {
    try {
      if (await this.askUserToReleaseReservation()) {
        this.loadingSubject.next(true);
        await this.reservationService.releaseSalesReservation(id).toPromise();
        this.snackBar.open('Vrijgegeven', '', {duration: 1000});
      }
    } finally {
      this.loadingSubject.next(false);
    }
  }

  async releaseProductionReservation(id: number) {
    try {
      if (await this.askUserToReleaseReservation()) {
        this.loadingSubject.next(true);
        await this.reservationService.releaseProductionReservation(id).toPromise();
        this.snackBar.open('Vrijgegeven', '', {duration: 1000});
      }
    } finally {
      this.loadingSubject.next(false);
    }
  }

  askUserToReleaseReservation(): Promise<Boolean> {
    return this.dialog.open(ReleaseAfroepDialogComponent)
      .afterClosed().toPromise();
  }

}
