<mat-card id="search-more">
    <mat-card-header>
        <div mat-card-avatar>
            <mat-icon>search</mat-icon>
        </div>
        <mat-card-title>Zoek meer</mat-card-title>
    </mat-card-header>
    <mat-card-actions class="buttons">
        <button mat-stroked-button aria-label="Zoek meer potten" routerLink="/voorraad/potten/zoeken/{{code}}">
            Potten
        </button>
        <button mat-stroked-button aria-label="Zoek meer stek" routerLink="/voorraad/stek/zoeken/{{code}}">
            Stek
        </button>
        <button mat-stroked-button aria-label="Zoek meer vollegrond" routerLink="/voorraad/vollegrond/zoeken/{{code}}">
            Vollegrond
        </button>
    </mat-card-actions>
</mat-card>
