import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable, of, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {StaleUpdateDialogComponent} from './stale-update-dialog/stale-update-dialog.component';

@Injectable()
export class BaseService {

    protected baseUrl = environment.apiUrl;

    constructor(
        protected http: HttpClient,
        public snackBar: MatSnackBar,
        private dialog: MatDialog) {}

    /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   * @param message - optional message to be shown to the user
   * @returns handle error callback
   */
    protected handleError<T>(operation = 'operation', result?: T, message?: string): (error: HttpErrorResponse) => Observable<T>  {
        return errorResponse => {
            // TODO: send the error to remote logging infrastructure, logs to console for now
            console.error(`${operation} failed with status code ${errorResponse.status}: ${errorResponse.message || 'No error message'}`);

            if (errorResponse.error.apierror !== undefined && errorResponse.error.apierror.message.indexOf('Failed to update stale') >= 0) {
              this.dialog.open(StaleUpdateDialogComponent).afterClosed();
              return throwError(errorResponse.error.apierror.message);
            }

            if (result !== undefined) {
                const errorMessage = 'Fout: ' + (message ? message : ('bij ' + operation));
                this.snackBar.open(errorMessage, 'sluit', { duration: 2000 });
                return throwError(errorMessage);
            }
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
