<h1 mat-dialog-title>Opmerking</h1>
<div mat-dialog-content>
  <mat-form-field>
    <textarea matInput [(ngModel)]="comments" tabindex="1" cdkFocusInitial></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-icon-button (click)="onRemoveClick()" color="warn"><mat-icon>delete</mat-icon></button>
  <button mat-icon-button (click)="onCancelClick()"><mat-icon>cancel</mat-icon></button>
  <button mat-icon-button (click)="onOkClick()" color="primary"><mat-icon>save</mat-icon></button>
</div>