import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import {AuthenticationService} from '../_services/authentication.service';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    queryParamSubscription: Subscription;
    error = '';

    @ViewChild('username') usernameField: ElementRef;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: new UntypedFormControl('', [Validators.required]),
            password: new UntypedFormControl('', [Validators.required])
        });

        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.queryParamSubscription = this.route.queryParams
            .subscribe(params => this.returnUrl = params['returnUrl'] || '/');
    }

    ngOnDestroy(): void {
        this.queryParamSubscription.unsubscribe();
    }

    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        if (!this.f.username.valid || !this.f.password.valid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .subscribe(
                () => this.router.navigate([this.returnUrl], { replaceUrl: true }),
                (error: HttpErrorResponse) => {
                    this.loading = false;
                    if (error.status === 401) {
                        this.error = 'Deze inloggegevens zijn onjuist';
                        this.usernameField.nativeElement.select();
                    } else {
                        this.error = error.message;
                    }
                });
    }
}
