import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SelectedLocation} from '../common/location-selector/locations';

@Injectable({
  providedIn: 'root'
})
export class LocationService implements OnDestroy {

  private readonly LOCAL_STORAGE_KEY_SELECTED_LOCATION = 'selectedLocation';
  private locationSubject: BehaviorSubject<SelectedLocation> = new BehaviorSubject(this.getSavedLocation());

  ngOnDestroy(): void {
    localStorage.setItem(this.LOCAL_STORAGE_KEY_SELECTED_LOCATION, this.locationSubject.getValue());

  }

  public setSelectedLocation(location: SelectedLocation) {
    this.locationSubject.next(location);
  }

  public observeSelectedLocation(): Observable<SelectedLocation> {
    return this.locationSubject.asObservable();
  }

  public getSelectedLocation(): SelectedLocation {
    return this.locationSubject.getValue();
  }

  private getSavedLocation(): SelectedLocation {
    return SelectedLocation[localStorage.getItem(this.LOCAL_STORAGE_KEY_SELECTED_LOCATION) as keyof typeof SelectedLocation]
      || SelectedLocation.All;
  }

  public isBedNrFromSelectedLocation(bednr: string): boolean {
    const char = bednr ? bednr.charAt(0).toLowerCase() : undefined;
    const selectedLocation: SelectedLocation = this.locationSubject.getValue() as SelectedLocation;
    switch (selectedLocation) {
      case SelectedLocation.All:
        return true;
      case SelectedLocation.Enschede:
        return this.isFromEns(char);
      case SelectedLocation.Nordkirchen:
        return this.isFromNoki(char);
      case SelectedLocation.Gregg:
        return this.isFromGregg(char);
      case SelectedLocation.Meddo:
        return this.isFromMeddo(char);
      case SelectedLocation.Bergsma:
        return this.isFromBergsma(bednr);
      case SelectedLocation.Novatec:
        return this.isFromNovatec(bednr);
      case SelectedLocation.Needseweg:
        return false;
      case SelectedLocation.Borculo:
        return !(
          this.isFromNoki(char) ||
          this.isFromEns(char) ||
          this.isFromGregg(char) ||
          this.isFromMeddo(char) ||
          this.isFromNovatec(char) ||
          this.isFromBergsma(bednr)
        );
      default:
        throw new Error(`Unknown SelectedLocation: ${selectedLocation}`);
    }
  }

  private isFromNoki(char: string): boolean {
    return char && (char === 'd' || char === 'n');
  }

  private isFromEns(char: string): boolean {
    return char && char === 'e';
  }

  private isFromGregg(char: string): boolean {
    return char && char === 'g';
  }

  private isFromMeddo(char: string): boolean {
    return char && char === 'm';
  }

  private isFromBergsma(char: string): boolean {
    return char && char === 'zberg';
  }

  private isFromNovatec(char: string): boolean {
    return char && char === 'z';
  }

  public isProductionLocationFromSelectedLocation(location: string): boolean {
    const currentLocation = this.getSelectedLocation() as SelectedLocation;
    return currentLocation === SelectedLocation.All || currentLocation === SelectedLocation[location];
  }
}
