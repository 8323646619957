<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Meer opties
    </mat-panel-title>
    <mat-panel-title class="panelTitleButton">
      <button mat-mini-fab id="buttonTitleHeader" color="primary" aria-label="Scan" (click)="openBarcodeScanDialog(); $event.stopPropagation()">
        <fa-icon [icon]="faBarcode"></fa-icon>
      </button>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div id="buttonBar">
    <mat-button-toggle-group id="dayToggle" #dayToggle="matButtonToggleGroup" [(ngModel)]="selectedDay"
                             (change)="refreshTable()">
      <mat-button-toggle value="ALL" aria-label="Alles">Alles</mat-button-toggle>
      <mat-button-toggle value="PAK" aria-label="Maandag of woensdag">Pakdag</mat-button-toggle>
    </mat-button-toggle-group>

    <div class="buttonBarRow">
      <app-location-selector></app-location-selector>
      <mat-slide-toggle id="hideCheckedToggle" color="primary" [(ngModel)]="hideChecked" (change)="hideCheckChanged()">
        Verberg afgecheckt
      </mat-slide-toggle>
    </div>

    <div class="buttonBarRow">
      <button mat-raised-button id="syncPakdagOrderItemsButton" [loading]="syncing" (click)="getCheckListOrders()" color="primary">
        <mat-icon>sync</mat-icon>
        Refresh
      </button>
      <button mat-raised-button color="primary" aria-label="Scan" (click)="openCameraScanDialog()">
        <fa-icon [icon]="faBarcode" size="xl"></fa-icon> Camera
      </button>
      <button mat-raised-button color="primary" aria-label="Scan" (click)="openBarcodeScanDialog()">
        <fa-icon [icon]="faBarcode" size="xl"></fa-icon> Barcode
      </button>
    </div>
  </div>
</mat-expansion-panel>


<div id="tableContainer" #scrollContainer mat-elevation-z8>
  <div class="loading-shade" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
  </div>
  <table mat-table [dataSource]="orderDataSource" matSort matSortActive="wk" matSortDirection="desc"
    [hidden]="orderDataSource?.data.length == 0">
    <ng-container matColumnDef="deliverNr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> AfleverNr </th>
      <td mat-cell *matCellDef="let element"> {{element.deliverNr}} </td>
      <td mat-footer-cell *matFooterCellDef> Totaal </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Naam </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      <td mat-footer-cell *matFooterCellDef> {{orderDataSource.data.length}} </td>
    </ng-container>

    <ng-container matColumnDef="aantal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Regels afgecheckt </th>
      <td mat-cell *matCellDef="let element"> {{getAmount(element)}}</td>
      <td mat-footer-cell *matFooterCellDef>{{getTotal()}}</td>
    </ng-container>

    <ng-container matColumnDef="waar">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Waar </th>
      <td mat-cell *matCellDef="let element"> {{element.dropOffLocation}} </td>
      <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <ng-container matColumnDef="wk">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Week </th>
      <td mat-cell *matCellDef="let element"> {{element.shipmentWeek}} </td>
      <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <ng-container matColumnDef="shipmentDay">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Dag </th>
      <td mat-cell *matCellDef="let element"> {{element.shipmentDay}} </td>
      <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <ng-container matColumnDef="pr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Pr </th>
      <td mat-cell *matCellDef="let element"> {{element.pr}} </td>
      <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <ng-container matColumnDef="amountOfLabelsNeeded">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Labels </th>
      <td mat-cell *matCellDef="let element"> {{element.amountOfLabelsNeeded}} </td>
      <td mat-footer-cell *matFooterCellDef> {{getTotalNeededLabels()}} </td>
    </ng-container>

    <ng-container matColumnDef="palletnumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Pallet# </th>
      <td mat-cell *matCellDef="let element"> {{element.layoutPallets}} </td>
      <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" [routerLink]="['/afchecklijst/', element.id]"
      [class.is-checked]="element.checked">
    </tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
  </table>
  <div id="no-orders" *ngIf="orderDataSource?.data.length == 0 && !(loading$ | async)">
    Voor deze locatie staan er geen bonnen klaar om afgecheckt te worden
  </div>
</div>
