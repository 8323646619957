import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-item-cancelled-dialog',
  templateUrl: './item-cancelled-dialog.component.html',
  styleUrls: ['./item-cancelled-dialog.component.scss']
})
export class ItemCancelledDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<boolean>
  ) {}

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

}

