import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSlideToggle, MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MatSliderChange} from '@angular/material/slider';
import {PotStock} from '../../pot';

@Component({
  selector: 'app-options-vrij-dialog',
  templateUrl: './options-vrij-dialog.component.html',
  styleUrls: ['./options-vrij-dialog.component.scss']
})
export class OptionsVrijDialogComponent implements AfterViewInit {

  private maxVrij: number;
  private originalVrij: number;
  private originalVr: string;
  percentage: number;

  @ViewChild('toggle', { static: true })
  toggle: MatSlideToggle;

  constructor(
    public dialogRef: MatDialogRef<OptionsVrijDialogComponent, PotStock>,
    @Inject(MAT_DIALOG_DATA) public potStock: PotStock
  ) {
    if (potStock) {
      this.originalVrij = potStock.amountFitForSale;
      this.originalVr = potStock.status;
      this.maxVrij = potStock.total;
      this.percentage = +((Math.max(0, potStock.amountFitForSale) / potStock.total) * 100).toFixed(0);
    }
  }

  ngAfterViewInit() {
    this.toggle.checked = this.potStock.status && this.potStock.amountFitForSale === -3000;
  }

  submit() {
    this.dialogRef.close(this.potStock);
  }

  onSliderChange(event: MatSliderChange) {
    if (this.toggle.checked) {
      this.toggle.toggle();
      this.potStock.status = '';
    }
    this.potStock.amountFitForSale = +((event.value / 100) * this.maxVrij).toFixed(0);
  }

  onToggleChange(event: MatSlideToggleChange) {
    if (event.checked) {
      this.potStock.amountFitForSale = -3000;
      this.potStock.status = '' + this.getCurrentWeekNumber();
    } else {
      this.potStock.amountFitForSale = +((this.percentage / 100) * this.maxVrij).toFixed(0);
      this.potStock.status = '';
    }
  }

  cancel() {
    // Reset to original
    this.potStock.amountFitForSale = this.originalVrij;
    this.potStock.status = this.originalVr;
    this.dialogRef.close();
  }

  private getCurrentWeekNumber(): number {
    const d: Date = new Date();
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    const yearStart: Date = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    return Math.ceil((((+d - +yearStart) / 86400000) + 1) / 7);
  }

}
