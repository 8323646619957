<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{order?.name}}
    </mat-panel-title>
    <mat-panel-title class="panelTitleButton">
        <button mat-mini-fab id="buttonTitleHeader" color="primary" aria-label="Scan" (click)="openBarcodeScanDialog(); $event.stopPropagation()">
          <fa-icon [icon]="faBarcode"></fa-icon>
        </button>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div id="buttonBar">
    <button mat-raised-button color="primary" aria-label="Scan" (click)="openScanDialog()">
      <fa-icon [icon]="faBarcode" size="xl"></fa-icon>
      scan
    </button>
    <app-location-selector></app-location-selector>
    <mat-slide-toggle color="primary" [(ngModel)]="hideChecked" (change)="hideCheckChanged()">Verberg afgecheckt
    </mat-slide-toggle>
  </div>
</mat-expansion-panel>

<div id="table-container" #scrollContainer mat-elevation-z8>
  <div class="loading-shade" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
  </div>
  <table id="order-item-table" mat-table [dataSource]="orderItemDataSource" matSort matSortActive="name" matSortDirection="asc" [hidden]="orderItemDataSource?.data.length == 0">
    <ng-container matColumnDef="checked">
      <th mat-header-cell *matHeaderCellDef>Rest. check</th>
      <td mat-cell *matCellDef="let element">
        <div>
          <button mat-button color="primary" (click)="checkRestHoog(element)">Hoog</button>
          <button mat-button color="primary" (click)="checkRestLaag(element)">Laag</button>
          <button mat-button color="primary" (click)="checkRestSamen(element)">Samen</button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="deliverAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Potten</th>
      <td mat-cell *matCellDef="let element"> {{element.deliverAmount}} </td>
    </ng-container>

    <ng-container matColumnDef="articleName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Naam</th>
      <td mat-cell *matCellDef="let element"> {{element.articleName}} </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Bed</th>
      <td mat-cell *matCellDef="let element"> {{element.location}} </td>
    </ng-container>

    <ng-container matColumnDef="vml">
      <th mat-header-cell *matHeaderCellDef> Laag <br> {{totalVml}}</th>
      <td mat-cell *matCellDef="let element">
        <app-counter [count]="element.scannedVml" (countChange)="vmlChanged($event, element)" [minimum]="0">
        </app-counter>
      </td>
    </ng-container>

    <ng-container matColumnDef="vmh">
      <th mat-header-cell *matHeaderCellDef> Hoog <br> {{totalVmh}}</th>
      <td mat-cell *matCellDef="let element">
        <app-counter [count]="element.scannedVmh" (countChange)="vmhChanged($event, element)" [minimum]="0">
        </app-counter>
      </td>
    </ng-container>

    <ng-container matColumnDef="mergedBoxes">
      <th mat-header-cell *matHeaderCellDef> Samen <br> {{totalMerged}} </th>
      <td mat-cell *matCellDef="let element">
        <app-counter [count]="element.scannedMerged" (countChange)="mergedBoxesChanged($event, element)" [minimum]="0">
        </app-counter>
      </td>
    </ng-container>

    <ng-container matColumnDef="neededLabels">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Labels <br> {{totalLabels}}</th>
      <td mat-cell *matCellDef="let element">{{element.neededLabels}}</td>
    </ng-container>

    <ng-container matColumnDef="remarks">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Opmerking</th>
      <td mat-cell *matCellDef="let element" id="commentField">
        {{element.comments}}
      </td>
    </ng-container>

    <ng-container matColumnDef="search">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Acties</th>
      <td mat-cell *matCellDef="let element">
        <button class="buttons" mat-mini-fab color="primary" [routerLink]="['/voorraad/potten/zoeken/', element.articleName]">
          <mat-icon>search</mat-icon>
        </button>
        <button class="buttons" mat-mini-fab color="primary" (click)= "openPrintingDialog(element)">
          <mat-icon>printer</mat-icon>
        </button>
        <button class="buttons" mat-mini-fab color="primary" *ngIf="!element.comments" (click)="openDialog(element)">
          <mat-icon>note_add</mat-icon>
        </button>
        <button class="buttons" mat-mini-fab color="primary" *ngIf="element.comments" (click)="openDialog(element)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>

      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" [class.is-checked]="element.allLabelsScanned"></tr>
  </table>
  <div id="no-order-items" *ngIf="orderItemDataSource?.data.length == 0 && !(loading$ | async)">
    Er staan geen regels klaar voor deze bon of locatie
  </div>
</div>
