<div class="loading-shade" *ngIf="busyFetching | async">
  <mat-spinner></mat-spinner>
</div>

<div class="fetch-data-bar">
<mat-form-field class="fill-space">
  <input matInput placeholder="Filteren" type="text" [(ngModel)]="sortKeyword"
         (keyup)="searchTermSubject.next(sortKeyword)" (keyup.enter)="searchTermSubject.next(sortKeyword)">
</mat-form-field>
  <app-location-selector></app-location-selector>
</div>

<div class="fetch-data-bar">
  <mat-form-field>
    <input matInput type="text" placeholder="Vanaf week (WW-YY)" [(ngModel)]="fromDate" pattern="(\d|([0-4]\d)|(5[0123]))[-]\d{2}"
           #fromDateRef="ngModel" [ngClass]="{ 'is-invalid': fromDateRef.invalid }" appAddHyphenAfterTwoDigits>
  </mat-form-field>
  <mat-form-field>
    <input matInput type="text" placeholder="Tot week (WW-YY)" [(ngModel)]="untilDate" pattern="(\d|([0-4]\d)|(5[0123]))[-]\d{2}"
           #untilDateRef="ngModel" [ngClass]="{ 'is-invalid': untilDateRef.invalid }" appAddHyphenAfterTwoDigits>
  </mat-form-field>

  <button mat-fab aria-label="Button to fetch data using weeks" color="primary"
          [disabled]="fromDateRef.invalid || untilDateRef.invalid" (click)="refreshData()">
    <mat-icon>refresh</mat-icon>
  </button>
</div>
<div class="centered-error-message" *ngIf="untilDateRef.invalid || fromDateRef.invalid">
  Voldoet niet aan format WW-YY
</div>
<div id="results" *ngIf="pottingJobs && pottingJobs.length > 0">
  <div id="results-header">
    <div otherCol>Hoeveelheid</div>
    <div otherCol>Datum</div>
    <div otherCol>Leverancier</div>
    <div otherCol>Locatie</div>
    <div otherCol>Opmerking</div>
    <div sizeCol>Maat</div>
    <div otherCol>Afronden</div>
  </div>
  <div #scrollContainer id="search-result-beds">
    <mat-card class="production-job" *ngFor="let job of pottingJobsToShow" [ngClass]="{'priority': job.priority}">
      <mat-card-title class="description"> {{job.plantName}} - {{job.plantCode}}
      </mat-card-title>
      <mat-card-content class="card-content">
        <div otherCol>{{job.orderedAmount}}</div>
        <div otherCol>{{job.deliveryDate}}</div>
        <div otherCol>{{job.supplier}}</div>
        <div otherCol>{{job.deliveryLocation}}</div>
        <div otherCol>{{job.remarks}}</div>
        <div sizeCol>{{job.targetUnitSizeName}}</div>
        <div otherCol>
          <button mat-icon-button aria-label="Button to open dialog to create stock"
                  (click)="openCreateStockDialog(job)">
            <mat-icon>assignment_turned_in</mat-icon>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
    <button mat-raised-button id="more-jobs-button" class="more-jobs-button" [disabled]="firstVisibleProdutionJobIndex >= filteredPottingJobs.length - 50" (click)="loadMoreItemsBottom()">
      <mat-icon aria-label="Load more">expand_more</mat-icon>
      Meer opdrachten laden
    </button>
  </div>
</div>
<div class="no-jobs-found-message" *ngIf="noJobsFoundMessage">{{noJobsFoundMessage}}</div>
