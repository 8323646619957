<h2 mat-dialog-title>Pas op, reserveringen!</h2>

<p class="warning-text mat-body-1">Deze voorraad heeft reserveringen! weet je zeker dat je deze actie wil uitvoeren?</p>
<mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">
    <i class="material-icons" aria-label="Annuleren">backspace</i>
    Annuleren
  </button>
  <button mat-raised-button (click)="onSubmitClick()">
    Bevestigen
  </button>
</mat-dialog-actions>
