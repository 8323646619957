<h2 mat-dialog-title>Let op!</h2>
<p>Vrij {{voorraad.amountFitForSale}} is meer dan totaal {{voorraad.total}}.<br>Wat moet vrij worden?</p>
  <mat-form-field appearance="outline">
    <mat-label>Vrij</mat-label>
    <input matInput type="number" placeholder="Vrij" [(ngModel)]="customVrij"
    (keyup.enter)="submit()">
  </mat-form-field>
  <mat-error *ngIf="vrijValidationError">{{vrijValidationError}}</mat-error>
<mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">
    <i class="material-icons" aria-label="Wijzigingen in voorraad annuleren">backspace</i>
    Annuleren
  </button>
  <button mat-raised-button color="primary" (click)="submit()">Ok</button>
</mat-dialog-actions>
