import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription, Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {debounceTime, filter} from 'rxjs/operators';
import {WithScrollContainer} from '../../_helpers/with-scroll-container';
import {BarcodePrintService} from './barcode-print.service';

@Component({
  selector: 'app-scan-print-list',
  templateUrl: './scan-print-list.component.html',
  styleUrls: ['./scan-print-list.component.scss']
})
export class ScanPrintListComponent extends WithScrollContainer() implements OnInit {

  barcode: number;
  barcodes: number[] = [];
  barcodeSubject = new Subject<number>();
  loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  subscriptions = new Subscription();

  constructor(
    private toastr: ToastrService,
    private barcodePrintService: BarcodePrintService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.barcodeSubject
      .pipe(
        debounceTime(100),
        filter(num => num !== undefined),
      )
      .subscribe(n => {
        this.barcodes.push(n);
        this.barcode = undefined;
      }));
  }

  ngOnDestroy() {
    this.barcodeSubject.unsubscribe();
    this.loadingSubject.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  remove(barcode: number) {
    this.barcodes = this.barcodes.filter(b => b !== barcode);
  }

  removeAll() {
    this.barcodes = [];
  }

  async save() {
    this.loadingSubject.next(true);
    try {
      const savedFileName = await this.barcodePrintService.printBarcodeList(this.barcodes).toPromise();
      this.toastr.success('Opgeslagen met naam: ' + savedFileName, 'Succes!', { timeOut: 10000 });
      this.removeAll();
    } catch (error) {
      this.toastr.error('Er is iets verkeerd gegaan');
    } finally {
      this.loadingSubject.next(false);
    }
  }

}
