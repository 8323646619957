<h2 mat-dialog-title>{{ data.rest }} {{ data.plantName }} rooien?</h2>
<mat-dialog-actions>
    <button mat-button (click)="cancel()">
        <i class="material-icons" aria-label="Annuleren">backspace</i>
        Annuleren
    </button>
    <button mat-button color="primary" (click)="confirm()">
        <i class="material-icons" aria-label="Bevestigen">arrow_upward</i>
        Bevestigen
    </button>
</mat-dialog-actions>
