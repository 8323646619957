import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {PotDetailComponent} from '../pot-detail.component';

@Component({
  selector: 'app-has-reservations-dialog',
  templateUrl: './has-reservations-dialog.component.html',
  styleUrls: ['./has-reservations-dialog.component.scss']
})
export class HasReservationsDialogComponent {

  constructor(public dialogRef: MatDialogRef<HasReservationsDialogComponent>) { }

  onSubmitClick(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
