import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SalesOrderItem} from '../../sales-order-item';

@Component({
  selector: 'app-opm-dialog',
  templateUrl: 'order-detail-opm-dialog.component.html',
  styleUrls: ['./order-detail-opm-dialog.component.scss']
})
export class OrderDetailOpmDialogComponent {

  public comments: string;

  constructor(
    public dialogRef: MatDialogRef<SalesOrderItem>,
    @Inject(MAT_DIALOG_DATA) public item: SalesOrderItem) {
      this.comments = item.comments;
     }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    this.item.comments = this.comments;
    this.dialogRef.close(this.item);
  }

  onRemoveClick(): void {
    this.item.comments = '';
    this.dialogRef.close(this.item);
  }

}
