import {Component} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PwaService} from '../../_services/pwa.service';

@Component({
  selector: 'app-pwa-controls',
  templateUrl: './pwa-controls.component.html',
  styleUrls: ['./pwa-controls.component.scss']
})
export class PwaControlsComponent {

  deferredInstallPrompt: any;
  installingPWA = false;

  constructor(
    public pwaService: PwaService,
    private snackbar: MatSnackBar) {

    // Listen to the window for beforeinstallprompt event which means the PWA is installable
    window.addEventListener('beforeinstallprompt', (event) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      event.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredInstallPrompt = event;
    });

    // Listen to the window for the appinstalled event which means the PWA is installed
    window.addEventListener('appinstalled', evt => {
      this.deferredInstallPrompt = null;
      this.installingPWA = false;
      this.snackbar.open('Heutinck Portaal is succesvol toegevoegd op je apparaat!', 'sluit', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 2000
      });
    });
  }

  install(): void {
    if (!this.installingPWA) {
      this.installingPWA = true;
      this.deferredInstallPrompt.prompt()
        .catch((reason: any) => this.installingPWA = false);
    }
  }

  update(): void {
    this.pwaService.update();
  }

}
