<div class="dialog-container">
<h2>Nieuwe stek regel toevoegen</h2>
  <div class="input-row">
    <app-auto-complete-text-field
      [fetchEntities]="plantService.fetchPlantsByCodeOrName.bind(plantService)"
      [preFilledEntityId]="null"
      [placeholder]="'Plantnaam'"
      (entityChange)="updateSelectedPlant($event)"
    ></app-auto-complete-text-field>
  </div>

  <div class="input-row">
    <mat-form-field >
      <input [ngClass]="{ 'is-invalid': !isPlantCodeValid, 'is-valid': isPlantCodeValid  }" matInput type="text" placeholder="Plantcode" [(ngModel)]="plantCode" (ngModelChange)="updatePlantCode($event)" />
      <mat-icon class="error-icon" *ngIf="!isPlantCodeValid">error</mat-icon>
      <mat-icon class="valid-icon" *ngIf="isPlantCodeValid">check_circle</mat-icon>
    </mat-form-field>
  </div>

  <div class="input-row">
    <mat-form-field>
      <input matInput type="text" placeholder="Bednummer" [(ngModel)]="bedNr"/>
    </mat-form-field>
  </div>

  <div class="input-row">
    <mat-form-field>
      <input matInput type="number" placeholder="Hoeveelheid" [(ngModel)]="amountOfPots"/>
    </mat-form-field>
  </div>

  <div class="input-row">
    <mat-form-field>
      <input matInput type="number" placeholder="Vrij" [(ngModel)]="amountFitForSale"/>
    </mat-form-field>
  </div>

  <div class="input-row">
    <mat-button-toggle-group [(ngModel)]="unitSizeName" aria-label="Maat">
      <mat-button-toggle value="STK">STK</mat-button-toggle>
      <mat-button-toggle value="D102">D102</mat-button-toggle>
      <mat-button-toggle value="D104">D104</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <mat-dialog-actions>
    <button mat-raised-button (click)="cancel()">
      <i class="material-icons" aria-label="Annuleren">backspace</i>
      Annuleren
    </button>
    <button [disabled]="isDisabled() || !isPlantCodeValid" mat-raised-button color="warn" (click)="onSubmitClick()">
      Bevestigen
    </button>
  </mat-dialog-actions>
</div>
