<div class="loading-shade" *ngIf="isLoading$ | async">
  <mat-spinner></mat-spinner>
</div>
<h1 mat-dialog-title>Split {{ original.plantName }}</h1>
<div mat-dialog-content>
  <div class="row">
    <span>Nieuwe partij</span>
    <span>Originele partij</span>
  </div>
  <div class="row">
    <mat-error *ngIf="splitAantalError">{{ splitAantalError }}</mat-error>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>Aantal</mat-label>
      <input matInput type="number" [(ngModel)]="splitAmount" [min]="0" [max]="original.total"
             (ngModelChange)="validateInputValues()">
    </mat-form-field>
    <span><i class="material-icons">compare_arrows</i> Totaal {{ original.total - splitAmount }}</span>
  </div>
  <div class="row">
    <mat-error *ngIf="splitVrijError">{{ splitVrijError }}</mat-error>
  </div>
  <div class="row">
    <mat-form-field>
      <mat-label>Vrij voor verkoop</mat-label>
      <input matInput type="number" [(ngModel)]="splitVrij" [max]="splitAmount" (ngModelChange)="calcOriginalVrij()">
    </mat-form-field>
    <div class="spacer"></div>
    <mat-form-field>
      <mat-label>Origineel vrij</mat-label>
      <input matInput type="number" [(ngModel)]="original.amountFitForSale" (ngModelChange)="validateInputValues()">
    </mat-form-field>
  </div>
  <mat-form-field>
    <mat-label>Bed</mat-label>
    <input matInput [(ngModel)]="location">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Opmerking</mat-label>
    <textarea matInput [(ngModel)]="remark"></textarea>
  </mat-form-field>
</div>

<div class="dual-list-selector">
  <mat-card>
    <mat-card-content>
      <div class="list-box-container">
        <div class="list-box available-items">
          <h3>Reserveringen overzetten</h3>
          <mat-selection-list [multiple]="true" (selectionChange)="onSelectionChange($event)">
            <mat-list-option *ngFor="let item of currentReservationsOnStock" [value]="item">
              {{ item.customerNameInternal }} | {{ item.amount }}
            </mat-list-option>
          </mat-selection-list>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">
    <i class="material-icons" aria-label="Wijzigingen in vrij annuleren">backspace</i>
    Sluit
  </button>
  <button mat-raised-button color="primary" *ngIf="!persistedSplittedItem" (click)="split()"
          [disabled]="splitAantalError || splitVrijError">
    <mat-icon>call_split</mat-icon>
    Split
  </button>
  <button mat-raised-button color="primary" *ngIf="persistedSplittedItem"
          [routerLink]="['/voorraad/potten/', this.persistedSplittedItem.id]" mat-dialog-close>
    <mat-icon>navigate_next</mat-icon>
    Open nieuwe partij
  </button>
</mat-dialog-actions>
