import {Component, Inject, OnInit} from '@angular/core';
import {PotService} from '../../pot.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PotStock} from '../../pot';
import {BehaviorSubject, Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {ChangeVrijDialogComponent} from '../change-vrij-dialog/change-vrij-dialog.component';
import {MatSelectionListChange} from '@angular/material/list';
import {ReservationService} from '../../../../reservation/reservation.service';
import {ReservedSalesStock} from '../../../../reservation/reservedStock';

@Component({
  selector: 'app-split-dialog',
  templateUrl: './split-dialog.component.html',
  styleUrls: ['./split-dialog.component.scss']
})
export class SplitDialogComponent implements OnInit {
  original: PotStock;
  splitAmount = 0;
  splitVrij = 0;
  location: string;
  remark: string;

  loadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.loadingSubject.asObservable();

  persistedSplittedItem: PotStock;
  splitAantalError: string;
  splitVrijError: string;

  reservedStocksIdToMove: ReservedSalesStock[] = [];
  currentReservationsOnStock: ReservedSalesStock[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private originalItem: PotStock,
    public dialogRef: MatDialogRef<SplitDialogComponent, PotStock[]>,
    private voorraadService: PotService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private reservationService: ReservationService
  ) {
    this.original = {...originalItem};
  }

  async ngOnInit(): Promise<void> {
    this.location = this.original.location;
    this.remark = this.original.remark;
    if (this.original.amountFitForSale === -3000) {
      this.splitVrij = -3000;
    } else if (this.original.amountFitForSale <= 0) {
      this.splitVrij = 0;
    }
    await this.fetchReservationsForCurrentStock();
  }

  private async fetchReservationsForCurrentStock() {
    this.currentReservationsOnStock = await this.reservationService.getReservedSalesStockByStockId(this.original.id).toPromise();
  }

  async split() {
    await this.validateRestVrij();
    this.loadingSubject.next(true);
    this.voorraadService.splitStock(
      this.original.id, this.splitAmount, this.splitVrij, this.location, this.remark, this.original.version,
      this.reservedStocksIdToMove.map(reservation => reservation.id)
    ).subscribe({
      next: result => this.handleResult(result),
      error: (err: any) => this.handleError(err)
    }).add(() => this.loadingSubject.next(false));
  }

  calcOriginalVrij() {
    this.validateInputValues();
    if (this.splitVrij >= 0 && this.original.amountFitForSale !== -3000) {
      this.original.amountFitForSale = Math.max(0, this.originalItem.amountFitForSale - this.splitVrij);
    }
  }

  private async validateRestVrij() {
    this.applyChangedToOriginal();
    if (this.original.rest < this.original.amountFitForSale) {
      return await this.dialog.open(ChangeVrijDialogComponent, {
        data: this.original,
        disableClose: true
      }).afterClosed().toPromise();
    } else {
      return Promise.resolve();
    }
  }

  private applyChangedToOriginal() {
    this.original.originalAmount -= this.splitAmount;
    this.recalulateRest(this.original);
  }

  private recalulateRest(item: PotStock) {
    item.rest = item.originalAmount
      - (item.dead || 0)
      - (item.amountReserved || 0)
      - (item.amountTrashed || 0);
  }

  validateInputValues() {
    if (this.splitAmount < 0) {
      this.splitAantalError = 'Mag niet minder zijn dan 0';
    } else if (this.splitAmount > this.original.total) {
      this.splitAantalError = `Mag niet meer zijn dan originele partij totaal: ${this.original.total}`;
    } else {
      this.splitAantalError = undefined;
    }

    this.splitVrijError = this.original.amountFitForSale > 0 && this.splitVrij > this.splitAmount
      ? `Kan niet meer zijn dan het te splitsen aantal: ${this.splitAmount}`
      : undefined;

    const totalSelectedReservationsAmount: number = this.reservedStocksIdToMove
      .reduce((total: number, reservation: ReservedSalesStock) => total + reservation.amount, 0);

    this.splitVrijError = totalSelectedReservationsAmount > this.splitAmount
      ? `Hoeveelheid om te splitsen moet gelijk of hoger zijn dan reserveringen die mee gaan: ${totalSelectedReservationsAmount}`
      : undefined;

    const totalAmountReservedForCurrentStock: number = this.currentReservationsOnStock
      .reduce((total: number, reservation: ReservedSalesStock) => total + reservation.amount, 0);

    this.splitAantalError = this.original.total - this.splitAmount < totalAmountReservedForCurrentStock - totalSelectedReservationsAmount
      ? 'Er mag niet meer gesplitst worden dan de overgebleven voorraad na aftrek van de geselecteerde reserveringen'
      : undefined;
  }

  private async handleResult(result: PotStock): Promise<void> {
    const newOriginal = await this.voorraadService.getStockById(this.original.id).toPromise();
    if (newOriginal) {
      this.originalItem = newOriginal;
    }
    if (result) {
      this.persistedSplittedItem = result;
      this.toastr.success('Nieuwe partij opgeslagen');
    }
    await this.fetchReservationsForCurrentStock();
  }

  private handleError(error: any): void {
    this.loadingSubject.next(false);
    this.toastr.error('Kon de voorraad partij niet splitsen, probeer het later nog eens of vraag Jordy om hulp');
    console.error('Error in splitting container stock item', error);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  onSelectionChange(event: MatSelectionListChange) {
    this.reservedStocksIdToMove = event.source.selectedOptions
      .selected
      .map(option => option.value);
    this.validateInputValues();
  }
}
