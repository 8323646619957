<h3 *ngIf="!scannerStarted">Bezig met openen van camera</h3>
<app-scanner id="scanner" (scanSuccess)="onScanSuccess($event)" (started)="onStarted($event)" [type]="['code_128']">
</app-scanner>
<div *ngIf="item">
    <div class="button-row">
        <strong>{{item.orderAmount}}</strong>
        <strong>{{item.articleName}}</strong>
    </div>
    <div class="button-row">
        <strong>{{item.remarks}}</strong>
        <strong>{{item.location}}</strong>
    </div>
    <div class="row">
        <div>Commentaar:</div>
        {{item.comments}}
        <button mat-icon-button color="primary" *ngIf="!item.comments" (click)="openDialog(item)">
            <mat-icon>note_add</mat-icon>
        </button>
        <button mat-icon-button color="primary" *ngIf="item.comments" (click)="openDialog(item)">
            <mat-icon>edit</mat-icon>
        </button>
    </div>
    <div class="row">
        <div>Totaal aantal labels:</div>
        {{item.neededLabels}}
    </div>

    <div class="row" [class.checked]="item.allLabelsScanned">
        <app-counter [count]="item.scannedVml" (countChange)="vmlChanged($event, item)" [minimum]="0">
        </app-counter>

        <app-counter [count]="item.scannedVmh" (countChange)="vmhChanged($event, item)" [minimum]="0">
        </app-counter>

        <app-counter [count]="item.scannedMerged" (countChange)="mergedBoxesChanged($event, item)" [minimum]="0">
        </app-counter>
    </div>

    <div class="button-row" [class.checked]="item.allLabelsScanned">
            <button mat-button color="primary" (click)="checkRestLaag()">Rest Laag</button>
            <button mat-button color="primary" (click)="checkRestHoog()">Rest Hoog</button>
            <button mat-button color="primary" (click)="checkRestSamen()">Rest Samen</button>
    </div>
</div>
<mat-dialog-actions class="button-row">
    <button [disabled]="!previousItem" mat-raised-button color="primary" (click)="setPreviousItem()">Vorige</button>
    <button mat-raised-button color="primary" aria-label="Scan" (click)="startScan()">
        <fa-icon [icon]="faBarcode" size="xl"></fa-icon>
    </button>
    <button mat-raised-button [mat-dialog-close]>
        <mat-icon>close</mat-icon>
    </button>
</mat-dialog-actions>
